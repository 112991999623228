import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class POSUserService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/posuser`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/posuser/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/posuser/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/posuser`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/posuser/`+id);
    }

    getAllPermissions(){
      return this.http.get(`${environment.baseUrl}/posuser/permission`);
    }

    getPermissions(id){
      let params:any = {per_page:1000};
      return this.http.get(`${environment.baseUrl}/posuser/`+id+`/permission`,{params:params});
    }

    setPermission(id,slug){
      return this.http.post(`${environment.baseUrl}/permission/set`,{pos_user:id,slug:slug});
    }
    
    deletePermission(id,slug){
      let body:any =  {
        body:{slug:slug} 
      };
      return this.http.delete(`${environment.baseUrl}/posuser/`+id+`/permission`,body);
    }

}