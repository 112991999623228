import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'paginated-select',
  templateUrl: './paginated-select.component.html',
  styleUrls: ['./paginated-select.component.scss']
})
export class PaginatedSelectComponent implements OnInit {

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreateRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() incomingEvent: EventEmitter<any>;
  @Input() service: any;
  @Input() fieldName: string = "Default";
  @Input() label: string[];
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() permissions: string[] = [];
  @Input() showCreate: boolean = false;
  @Input() row: any;
  @Input() optionValue:string = 'id';
  @Input() limit:number = 25;
  @Input() hideSelectedValues:boolean = true;
  @Input() selectedValues:number[] = [9874,123123];

  constructor(
    private cdr: ChangeDetectorRef,
    private fb : FormBuilder
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm();
    this.loadRows();
    if (this.row){
      this.form.get('row').setValue(this.row);
    }
    if (this.incomingEvent) {
      this.incomingEvent.subscribe(res => {
        console.log(res);
        if (res['type'] == 'add_new_client') {
          let searchName = "";
          for (let i = 0; i < _this.label.length; i++) {
            let lbl = _this.label[i];
            searchName += (i == _this.rows.length ? res['client'][lbl] : res['client'][lbl] + ' ');
          }
          res['client']['search_name'] = searchName;

          _this.rows = [res['client'], ..._this.rows];
          _this.row = res['client'].id;
          _this.form.get('row').setValue(_this.row);
          _this.cdr.detectChanges();
        }
        else if(res['type'] == 'clear'){
          _this.form.get('row').setValue(null);
          _this.row = null;
          _this.cdr.detectChanges();
        }
        else {
          _this.row = res.id;
          _this.form.get('row').setValue(_this.row);
          _this.rows.push(res);
        }
      })
    }
  }

  hasValue(row){
    return this.selectedValues.includes(+row[this.optionValue]);
  }

  // getFilteredList(){
  //   let _this = this;
  //   return this.rows.filter(row => {
  //     return !_this.selectedValues.includes(+row[_this.optionValue]);
  //   });
  // }

  form:FormGroup;
  createForm(){
    let _this = this;

    this.form = this.fb.group({
      row:['',(this.required ? Validators.required : Validators.nullValidator)],
    });

    this.form.get('row').valueChanges.subscribe(res => {
      _this.onChange(res);
    }); 
  }

  isTouched(controlName) {
    if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  fetchMore() {
    this.loadRows();
  }

  onChange(event) {
    this.valueChange.emit(event);
  }

  sendCreateRequest() {
    this.onCreateRequest.emit(true);
  }

  loadRows() {
    let _this = this;
    if (this.rearchedCap) {
      return true;
    }

    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }

    if (_this.subscription) {
      _this.subscription.unsubscribe();
    }

    _this.timeout = setTimeout(() => {

      _this.subscription = _this.service.getAll({ per_page: _this.limit, page: _this.lastPage }).subscribe(res => {
        if (!_this.rows) {
          _this.rows = [...res['data']];
        }
        else {
          _this.rows = [..._this.rows, ...res['data']];
        }
        if (+_this.lastPage == +res['meta']['last_page']) {
          _this.rearchedCap = true;
        }

        for (let row of _this.rows) {
          let searchName = "";
          for (let i = 0; i < _this.label.length; i++) {
            let lbl = _this.label[i];
            searchName += (i == _this.rows.length ? row[lbl] : row[lbl] + ' ');
          }
          row['search_name'] = searchName;
        }
        
        let filtered:Map<number,any> = new Map<number,any>();
        _this.rows.map((val,key) => {
          filtered.set(val.id,val);
        });
        _this.rows = [...Array.from(filtered.values())];
        _this.lastPage++;
      },
        err => {
          console.log(err);
        })
    }, 350);
  }

  rows: any[] = [];
  timeout: any;
  lastPage = 1;
  rearchedCap: boolean = false;
  subscription: Subscription;
  onSearch(event) {
    let term = event;
    let _this = this;
    this.lastPage = 1;
    this.rearchedCap = false;
    this.rows = [];
    if (term == null || term == '') {
      this.loadRows();
      return;
    }
    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }
    _this.timeout = setTimeout(() => {
      if (_this.subscription) {
        _this.subscription.unsubscribe();
      }
      this.service.getAll({ q: term, per_page: _this.limit, page: this.lastPage }).subscribe(res => {
        _this.rows = res['data'];
        for (let row of _this.rows) {
          let searchName = "";
          for (let i = 0; i < _this.label.length; i++) {
            let lbl = _this.label[i];
            searchName += row[lbl];
          }
          row['search_name'] = searchName;
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }
}