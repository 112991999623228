import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { VoucherModel } from '../../../shared/models/voucher.model';
import { Page } from '../../../shared/models/page.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { Utils } from '../../../shared/services/utils.service';
import { VoucherService } from '../../../shared/services/voucher.service';
import { moment } from 'ngx-bootstrap/chronos/test/chain';

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.scss']
})
export class VoucherListComponent implements OnInit {


  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  freeSearch: string;
  vouchers: VoucherModel[];
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];
  
  searchParams = { per_page: environment.per_page, page: this.currentPage };

  constructor(
    private router: Router,
    private voucherService: VoucherService,
    private authService: AuthService,
    private messageService : MessageService,
    private utils : Utils,
    private route : ActivatedRoute
  ) {

    }

  ngOnInit() {
    this.loadFilters();
    this.loadVouchers();
    this.columns = [
      { name: 'Name', flexGrow: 1, sortable: true, cellClass: 'Voucher_name', headerClass: 'Voucher_name' },
      { name: 'Email', flexGrow: 3, sortable: true, cellClass: 'Voucher_email', headerClass: 'Voucher_email' }
    ];
    this.updateMenuItems();
  }

  onSort(event) {
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    this.loadVouchers();
  }

  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  isLoading:boolean = false;
  loadVouchers() {
    let _this = this;
    _this.vouchers = [];
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    _this.isLoading = true;
    this.voucherService.getAll(this.searchParams).subscribe(res => {
      res['meta']['per_page'] = +res['meta']['per_page']; 
      _this.page = res['meta'];
      _this.vouchers = res['data'];
      _this.rows = [];
      for (let voucher of _this.vouchers) {
        _this.rows.push(
          {
            id: voucher.id,
            voucher_number: voucher.voucher_number,
            email: voucher.email,
            recipient:voucher.first_name+' '+voucher.last_name,
            redeemed_at:voucher.redeemed_at,
            topup: voucher.topup_credit
          }
        );
      }
      if(res['data'].length == 0){
        //this.messageService.error("No Vouchers Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }
      console.log(_this.page);
      _this.loading.isLoading = false;
      _this.isLoading = false;
    },
    err => {
      _this.loading.isLoading = false;
      _this.isLoading = false;
      console.log(err);
    });
  }

  getVoucherRedeemFiltered(row){
    return "Redeemed on "+moment(row.redeemed_at).format("DD/MM/YYYY HH:mm");
  }

  hasPermission(permission){
    return this.authService.hasPermission(permission);
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    this.searchParams.page = this.currentPage;
    this.loadVouchers();
    this.setFilter("pageVoucher",""+this.currentPage);
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  onFocus(event) {
    if (event['type'] == 'click') {
      let id = event['row']['id'];
      this.router.navigate(['/voucher/' + id]);
    }
  }

  clearFilters() {
    this.freeSearch = "";
    this.updateFilters();
  }

  historyChange:EventEmitter<boolean> = new EventEmitter<boolean>();
  filteredParams:any = {};
  filterUpdateTimeout:any;
  setFilter(name, value) {
    let _this = this;
    _this.filteredParams = {..._this.filteredParams,[name]:value};
    this.utils.pushFiltersToRouter(value,name,_this.filteredParams).subscribe(res => {
      window.history.pushState(null,"",("voucher"+res));
      _this.historyChange.emit(true);
    });
  }

  getFilter(name): any {
    let value = this.route.snapshot.queryParams[name];
    if(isNaN(+value)){
      return value;
    }
    return +value;
  }

  saveFilters() {
    let _this = this;
    setTimeout(() => {
      _this.setFilter("pageVoucher", _this.currentPage);
      _this.setFilter("searchVoucher", _this.freeSearch);
    }, 250);
  }

  loadFilters() {
    this.freeSearch = this.getFilter("searchVoucher");
    this.currentPage = (this.getFilter("pageVoucher") ? this.getFilter("pageVoucher") : 1);
    this.updateFilters();
  }

  filterLoadTimeout:any
  updateFilters(skip?) {
    let _this = this;
		_this.searchParams = { per_page: environment.per_page, page: 1 };
		if (!skip) {
		_this.searchParams.page = _this.currentPage;
		}
		else {
		_this.currentPage = 1;
		_this.setFilter("pageVoucher", 1);
		}
    if (this.freeSearch) {
      this.searchParams['q'] = this.freeSearch;
    }
    if (this.filterLoadTimeout) {
			clearTimeout(this.filterLoadTimeout);
		}
		this.filterLoadTimeout = setTimeout(() => {
			this.loadVouchers();
		}, 350);
    this.saveFilters();
  }


  //List Dot Menu Bulk Selection

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  openBulk() {
    this.mdSlideUp.show()
  }

  mainToggle: boolean = false;
  activeActionTitle: string;
  menuItems: any[] = [
    { action: 'export-selected', message: 'Export Selected', class: '', icon: 'fal fa-file-export', permissions: ['view_voucher'] },
    { action: 'export-all', message: 'Export All', class: '', icon: 'fal fa-file-export', permissions: ['view_voucher'] },
  ];

  handleButtonPress(event) {
    switch (event) {
      case "export-selected":
        this.activeActionTitle = "Export Selected";
        this.openBulk();
        break;
      case "export-all":
        this.activeActionTitle = "Export All";
        this.openBulk();
        break;
      default:
        console.log(event);
        break;
    }
  }

  
  activeRows:Map<number,any> = new Map<number,any>();
  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if(row['bulk_checked']){
      _this.activeRows.set(row['id'],row);
    }
    else{
      _this.activeRows.delete(row['id']);
    }
    this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }

  updateMenuItems(){
    for(let item of this.menuItems){
      if(this.activeRows.size == 0){
        if(item.action.includes('export-selected')){
          item.Disabled = true;
        }
      }
      else{
        if(item.action.includes('export-selected')){
          item.Disabled = false;
        }
      }
    }
    return this.menuItems;
  }

  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if(this.mainToggle == true){
        _this.activeRows.set(item.id,item);
      }
      else{
        _this.activeRows.delete(item.id);
      }
    }
    this.updateMenuItems();
  }

  submitToggle() {
    this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), 'Voucher');
    this.activeRows = new Map<number,any>();
  }

}