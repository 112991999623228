import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { isArray } from 'util';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { POSUserService } from '../../../shared/services/pos-user.service';
import { POSUserModel } from '../../../shared/models/pos-user.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pos-user-detail',
  templateUrl: './pos-user-detail.component.html',
  styleUrls: ['./pos-user-detail.component.scss']
})
export class POSUserDetailComponent implements OnInit {

  @Input() external:boolean = false;
  @Output() saveEvent:EventEmitter<any> = new EventEmitter<any>();
  posUser: POSUserModel;
  posuserForm: FormGroup;
  roles: any[];
  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  errors: String[] = [];
  ColumnMode = ColumnMode;
  constructor(
    private cdr: ChangeDetectorRef,
    private posuserService: POSUserService,
    private fb: FormBuilder,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private _location: Location
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.loadLocalities();
    this.loadPermissions();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadposuser(res['params']['id']);
      }
    })
  }

  activePermissions:string[] = [];
  permissions:any[] = [];
  loadPermissions(){
    let _this = this;
    this.posuserService.getAllPermissions().subscribe(res => {
      _this.permissions = res['data'];
    },
    err => {
      console.log(err);
    })
  }

  loadActivePermissions(){
    let _this = this;
    this.activePermissions = [];
    this.posuserService.getPermissions(this.posUser.id).subscribe(res => {
      if(res['data'] && res['data'].length > 0){
        for(let val of res['data']){
          _this.activePermissions.push(val.slug);
        }
        for(let perm of _this.permissions){
          if(this.hasPermissionPOS(perm)){
            perm.toggled = true;
          }
          else{
            perm.toggled = false;
          }
          _this.cdr.detectChanges();
        }
      }
      //_this.activePermissions = res['data'];
    },
    err => {
      console.log(err);
    })
  }

  hasPermissionPOS(permission){
    return this.activePermissions.includes(permission.slug);
  }

  menuItems: any[] = [
    { action: 'delete', message: 'Delete POS User', class: '', icon: 'fal fa-trash', permissions: ['delete_pos_user'] }
  ];

  handleButtonPress(event) {
    switch (event) {
      case "delete":
        this.mdSlideUp.show();
        break;
      default:
        console.log(event);
        break;
    }
  }

  subscription:Subscription;
  toggle(event,permission) {
    event.preventDefault();
    console.log(this.activePermissions);
    console.log(permission);
    let _this = this;
    if(this.subscription){
      this.subscription.unsubscribe();
    }

    if (!this.hasPermissionPOS(permission)) {
      _this.subscription = this.posuserService.setPermission(this.posUser.id, permission.slug).subscribe(res => {
        this.loadActivePermissions();
        this.messageService.remove();
        this.messageService.success("POS User Permissions Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      },
        err => {
          console.log(err);
        })
    }
    else {
      _this.subscription = this.posuserService.deletePermission(this.posUser.id, permission.slug).subscribe(res => {
        this.loadActivePermissions();
        this.messageService.remove();
        this.messageService.success("POS User Permissions Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      },
        err => {
          console.log(err);
        })
    }
  }

  loadLocalities() {
    let _this = this;
    // this.localityService.getAll().subscribe(res => {
    //   _this.localities = res['data'];
    // },
    // err => {
    //   console.log(err);
    // })
  }

  @ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
  loadSavedData() {
    if (localStorage.getItem("posuser-create")) {
      this.createForm(JSON.parse(localStorage.getItem("posuser-create")));
      // if (this.posuserForm.get('phone').value) {
      //   this.posuserForm.get('phone').setValue(this.posuserForm.get('phone').value['nationalNumber'].replace(" ", ""));
      // }
    }
    this.posuserForm.markAllAsTouched();
    this.loadDataModal.hide();
  }

  deleteData() {
    localStorage.removeItem("posuser-create");
    this.loadDataModal.hide();
  }

  sendBack() {
    this._location.back();
  }

  page: Page[] = [new Page(), new Page(), new Page()];
  currentPage: number[] = [1, 1, 1];
  searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

  markTouched() {
    this.posuserForm.markAsTouched();
  }

  createForm(input?) {
    input = input || {};
    this.posuserForm = this.fb.group({
      first_name: [input.first_name, Validators.required],
      last_name: [input.last_name, Validators.required],
      email: [input.email, [Validators.email]],
      pin: [input.pin,[Validators.required, Validators.minLength(6),Validators.maxLength(6),Validators.pattern('[0-9]*')]],
    });
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  uploadFile(fileInput) {
    console.log(fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        console.log(e.target.result);
        //$('#preview').attr('src', e.target.result);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  isTouched(controlName) {
    if (this.posuserForm.get(controlName).touched && this.posuserForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  
  loadposuser(id) {
    let _this = this;
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    this.posuserService.get(id).subscribe(res => {
      _this.posUser = res['data'];
      _this.loadActivePermissions();
      _this.createForm(_this.posUser);
      _this.loading.isLoading = false;
    },
    err => {
      _this.loading.isLoading = false;
      console.log(err);
    });
  }

  delete() {
    let _this = this;
    this.isLoading = true;
    this.posuserService.delete(this.posUser.id).subscribe(res => {
      this.router.navigate(['/posuser']);
      this.messageService.remove();
      this.messageService.success("posuser Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.isLoading = false;
    },
      err => {
        this.messageService.remove();
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
        _this.isLoading = false;
      });
  }

  showSlideUp() {
    this.mdSlideUp.show();
  }

  @Input() isLoading:boolean = false;
  save() {
    this.posuserForm.updateValueAndValidity();
    if (this.posuserForm.invalid) {
      this.posuserForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }

    if(this.isLoading){
      return;
    }

    localStorage.removeItem("posuser-create");
    let _this = this;

    _this.isLoading = true;

    let rawValue = this.posuserForm.getRawValue();
    this.posuserForm.markAsUntouched();
    this.posuserForm.updateValueAndValidity();
    
    if (this.posUser) {
      this.posuserService.edit(this.posUser.id, rawValue).subscribe(res => {
        this.messageService.remove();
        this.messageService.success("POS User Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(res['data']);
        _this.createForm(res['data']);
        console.log(_this.posuserForm);
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          this.messageService.remove();
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          console.log(err);
          _this.isLoading = false;
        });
    }
    else {
      this.posuserService.create(rawValue).subscribe(res => {
        if(_this.external == true){
          _this.saveEvent.emit(res['data']);
        }
        else{
          this.router.navigate(['/posuser/' + res['data']['id']]);
        }
        this.messageService.remove();
        this.messageService.success("POS User Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          console.log(err);
          _this.isLoading = false;
        });
    }
  }


}
