import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/guest`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/guest/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/guest/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/guest`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/guest/`+id);
    }

}