import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'dot-menu',
  templateUrl: './dot-menu.component.html',
  styleUrls: ['./dot-menu.component.scss']
})
export class DotMenuComponent {


  state:boolean = false;
  @Input() isButtonMode:boolean = true;
  @Input() disabled:boolean = false;
  @Input() menuItems:any[] = [];
  @Input() isHorizontal:boolean = false;
  filteredMenu:any = [];
  @Output() submitEvent:EventEmitter<any> = new EventEmitter<any>();
  @Output() visibilityChanged:EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private permissionService : NgxPermissionsService
  ) { }

  getMenuItems():any[]{
    let _this = this;
    let newMenu:any[] = this.menuItems.filter(item => {
      if(item != null && item['action'] == "delete"){
        item['custom_class'] = "delete-menu-item";
      }
      if((!item.permissions || item.permissions.length == 0 || (item.permissions.length == 1 && item.permissions[0] == '') && !item['action'].includes("export-"))){
        return item;
      }
      else{
        if(!item.hidden && !item['action'].includes("export-")){
          for(let permission of item.permissions){
            if(_this.permissionService.getPermission(permission)){
              return item;
            }
          } 
        }
      }
    });
    //console.log(newMenu);
    return newMenu;
  }
  
  getExports(){
    let _this = this;
    let newMenu:any[] = this.menuItems.filter(item => {
      if(item != null && item['action'] == "delete"){
        item['custom_class'] = "delete-menu-item";
      }
      if((!item.permissions || item.permissions.length == 0 || (item.permissions.length == 1 && item.permissions[0] == '') && item['action'].includes("export-"))){
        return item;
      }
      else{
        if(!item.hidden && item['action'].includes("export-")){
          for(let permission of item.permissions){
            if(_this.permissionService.getPermission(permission)){
              return item;
            }
          } 
        }
      }
    });
    return newMenu;
  }

  timeout:any;
  cancelRemove(){
    clearTimeout(this.timeout);
  }

  toggleState(){
    this.state = !this.state;
    if(this.state){
      this.visibilityChanged.emit(this.state);
    }
  }

  removeState(){
    let _this = this;
    this.timeout = setTimeout(() => {
      _this.state = false;
    }, 500);
  }

  onButtonPress(item:any){
    console.log(item);
    if(item.Disabled){
      return;
    }
    this.submitEvent.emit(item.action);
  }

}
