import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from '../../../shared/models/page.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { Utils } from '../../../shared/services/utils.service';
import { EventService } from '../../../shared/services/event.service';
import { EventModel } from '../../../shared/models/event.model';
import * as moment from 'moment';
import { TokenModel } from '../../../shared/models/token.model';
import { TokenService } from '../../../shared/services/token.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {


  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @Input() forceReload: EventEmitter<any>;
  @Input() external: boolean = false;
  @Input() token:TokenModel;
  freeSearch: string;
  events: EventModel[];
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];

  searchParams = { per_page: environment.per_page, page: this.currentPage };

  constructor(
    private router: Router,
    private eventService: EventService,
    private authService: AuthService,
    private messageService: MessageService,
    private utils: Utils,
    private route : ActivatedRoute,
    private tokenService : TokenService
  ) {

  }

  @Input() startInput: Date;
  @Input() endInput: Date;

  setDateRange(event) {
    let dates: Date[] = event;
    this.startInput = moment(dates[0]).startOf('d').toDate();
    this.endInput = moment(dates[1]).endOf('d').toDate();
    this.updateFilters();
    this.loadEvents();
  }

  getEventDate(row){
    return moment(row.event_start).format('DD/MM/YYYY HH:mm')+' - '+moment(row.event_finish).format('DD/MM/YYYY HH:mm');
  }

  @ViewChild('createEvent', { static: true }) createEventModal: ModalDirective;
  addEvent(event: EventModel) {
    this.createEventModal.hide();
    this.loadEvents();
  }

  ngOnInit() {
    this.loadFilters();
    this.loadEvents();
    this.columns = [
      { name: 'Name', flexGrow: 1, sortable: true, cellClass: 'Event_name', headerClass: 'Event_name' },
      { name: 'Email', flexGrow: 3, sortable: true, cellClass: 'Event_email', headerClass: 'Event_email' }
    ];
    this.updateMenuItems();
    if(this.forceReload){
      this.forceReload.subscribe(res => {
        this.loadEvents();
      })
    }
  }

  onSort(event) {
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    this.loadEvents();
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  timeout: any;
  isLoading:boolean = false;
  loadEvents() {
    let _this = this;
    _this.events = [];
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    if (this.external) {
      if (this.startInput && this.endInput) {
        this.searchParams['date_from'] = moment(this.startInput).format();
        this.searchParams['date_to'] = moment(this.endInput).format();
      }
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      _this.isLoading = true;
      if(!_this.token){
        _this.eventService.getAll(_this.searchParams).subscribe(res => {
          res['meta']['per_page'] = +res['meta']['per_page'];
          _this.page = res['meta'];
          _this.events = res['data'];
          _this.rows = [];
          for (let Event of _this.events) {
            _this.rows.push(
              {
                id: Event.id,
                title: Event.title,
                description: Event.description,
                event_start: Event.event_start,
                event_finish: Event.event_finish,
                attended: Event.attended
              }
            );
          }
          if(_this.rows.length == 0){
            //this.messageService.error("No Events Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          }
          console.log(_this.page);
          _this.loading.isLoading = false;
          _this.isLoading = false;
        },
          err => {
            _this.loading.isLoading = false;
            _this.isLoading = false;
            console.log(err);
          });
      }
      else{
        _this.tokenService.getLinkedEvents(_this.token.id).subscribe(res => {
          res['meta']['per_page'] = +res['meta']['per_page'];
          _this.page = res['meta'];
          _this.events = res['data'];
          _this.rows = [];
          for (let Event of _this.events) {
            _this.rows.push(
              {
                id: Event.id,
                title: Event.title,
                description: Event.description,
                event_finish: Event.event_finish,
                attended: Event.attended
              }
            );
          }
          if(_this.rows.length == 0){
            //this.messageService.error("No Events Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          }
          console.log(_this.page);
          _this.loading.isLoading = false;
        },
          err => {
            _this.loading.isLoading = false;
            console.log(err);
          });
      }
    }, 350);

    

  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    this.searchParams.page = this.currentPage;
    this.loadEvents();
    this.setFilter("pageEvent", "" + this.currentPage);
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  onFocus(event) {
    if (event['type'] == 'click') {
      let id = event['row']['id'];
      this.router.navigate(['/posuser/' + id]);
    }
  }

  clearFilters() {
    this.freeSearch = "";
    this.startInput = null;
    this.endInput = null;
    this.updateFilters();
  }

  historyChange:EventEmitter<boolean> = new EventEmitter<boolean>();
  filteredParams:any = {};
  filterUpdateTimeout:any;
  setFilter(name, value) {
    let _this = this;
    _this.filteredParams = {..._this.filteredParams,[name]:value};
    this.utils.pushFiltersToRouter(value,name,_this.filteredParams).subscribe(res => {
      window.history.pushState(null,"",("event"+res));
      _this.historyChange.emit(true);
    });
  }

  getFilter(name): any {
    let value = this.route.snapshot.queryParams[name];
    if(isNaN(+value)){
      return value;
    }
    return +value;
  }

  saveFilters() {
    let _this = this;
    setTimeout(() => {
      localStorage.removeItem("searchEvent");
      _this.setFilter("pageEvent", _this.currentPage);
      _this.setFilter("searchEvent", _this.freeSearch);
      if (_this.startInput && _this.endInput) {
        _this.setFilter("dateFromEvent", moment(_this.startInput).valueOf());
        _this.setFilter("dateToEvent", moment(_this.endInput).valueOf());
      }
      else {
        _this.setFilter("dateFromEvent", null);
        _this.setFilter("dateToEvent", null);
      }
    }, 250);
  }

  loadFilters() {
    if (this.external) {
      this.updateFilters();
      return;
    }
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.getItem(localStorage.key(i)) == "undefined" || localStorage.getItem(localStorage.key(i)) == undefined || localStorage.getItem(localStorage.key(i)) == null) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    this.freeSearch = this.getFilter("searchEvent");
    this.currentPage = (this.getFilter("pageEvent") ? this.getFilter("pageEvent") : 1);
    if (this.getFilter("dateFromEvent") && this.getFilter("dateToEvent")) {
      this.startInput = moment(this.getFilter("dateFromEvent")).toDate();
      this.endInput = moment(this.getFilter("dateToEvent")).toDate()
    }
    this.updateFilters();
  }

  filterLoadTimeout: any
  updateFilters(skip?) {
    let _this = this;
    _this.searchParams = { per_page: environment.per_page, page: 1 };
    if (!skip) {
      _this.searchParams.page = _this.currentPage;
    }
    else {
      _this.currentPage = 1;
      _this.setFilter("pageEvent", 1);
    }
    if (this.freeSearch) {
      this.searchParams['q'] = this.freeSearch;
    }

    if (this.startInput && this.endInput) {
      this.searchParams['date_from'] = moment(this.startInput).format();
      this.searchParams['date_to'] = moment(this.endInput).format();
    }

    if (this.external) {
      return;
    }

    if (this.filterLoadTimeout) {
      clearTimeout(this.filterLoadTimeout);
    }
    this.filterLoadTimeout = setTimeout(() => {
      this.loadEvents();
    }, 350);
    this.saveFilters();
  }


  //List Dot Menu Bulk Selection

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  openBulk() {
    this.mdSlideUp.show()
  }

  mainToggle: boolean = false;
  activeActionTitle: string;
  menuItems: any[] = [
    { action: 'export-selected', message: 'Export Selected', class: '', icon: 'fal fa-file-export', permissions: ['view_event'] },
    { action: 'export-all', message: 'Export All', class: '', icon: 'fal fa-file-export', permissions: ['view_event'] }
  ];

  handleButtonPress(event) {
    switch (event) {
      case "export-selected":
        this.activeActionTitle = "Export Selected";
        this.openBulk();
        break;
      case "export-all":
        this.activeActionTitle = "Export All";
        this.openBulk();
        break;
      default:
        console.log(event);
        break;
    }
  }


  activeRows: Map<number, any> = new Map<number, any>();
  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if (row['bulk_checked']) {
      _this.activeRows.set(row['id'], row);
    }
    else {
      _this.activeRows.delete(row['id']);
    }
    this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }

  updateMenuItems() {
    for (let item of this.menuItems) {
      if (this.activeRows.size == 0) {
        if (item.action.includes('export-selected')) {
          item.Disabled = true;
        }
      }
      else {
        if (item.action.includes('export-selected')) {
          item.Disabled = false;
        }
      }
    }
    return this.menuItems;
  }

  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if (this.mainToggle == true) {
        _this.activeRows.set(item.id, item);
      }
      else {
        _this.activeRows.delete(item.id);
      }
    }
    this.updateMenuItems();
  }

  submitToggle() {
    this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), 'Event');
    this.activeRows = new Map<number, any>();
  }

}