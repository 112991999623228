import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from '../../../shared/models/page.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { Utils } from '../../../shared/services/utils.service';
import { ReportService } from '../../../shared/services/report.service';
import * as moment from 'moment';

@Component({
  selector: 'app-report-refund-list',
  templateUrl: './report-refund.component.html',
  styleUrls: ['./report-refund.component.scss']
})
export class RefundComponent implements OnInit {


  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @Input() external: boolean = false;
  freeSearch: string;
  refunds: any[];
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];

  searchParams = { per_page: environment.per_page, page: this.currentPage };

  constructor(
    private router: Router,
    private reportService: ReportService,
    private authService: AuthService,
    private messageService: MessageService,
    private utils: Utils
  ) {

  }

  @Input() startInput: Date;
  @Input() endInput: Date;

  setDateRange(event) {
    let dates: Date[] = event;
    console.log(event);
    this.startInput = moment(dates[0]).startOf('d').toDate();
    this.endInput = moment(dates[1]).endOf('d').toDate();
    this.updateFilters();
    //this.loadRefunds();
  }

  periodsMap: Map<number, any> = new Map<number, any>();
  periods: any[] = [{ id: 1, title: "Last 24 Hours" }, { id: 2, title: "Last Week" }, { id: 3, title: "Last Month" }, { id: 4, title: 'Custom Range' }];
  activePeriod: number = 1;
  ngOnInit() {
    this.loadFilters();
    this.loadRefunds();
    if (!this.external) {
      this.loadTotals();
    }
    this.columns = [
      { name: 'Name', flexGrow: 1, sortable: true, cellClass: 'Refund_name', headerClass: 'Refund_name' },
      { name: 'Email', flexGrow: 3, sortable: true, cellClass: 'Refund_email', headerClass: 'Refund_email' }
    ];
    this.updateMenuItems();
    for (let v of this.periods) {
      this.periodsMap.set(v.id, v.title);
    }
  }

  onSort(event) {
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    this.loadRefunds();
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  timeout: any;
  loadRefunds() {
    let _this = this;
    _this.refunds = [];
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }

    // if (this.external) {
    //   if (this.startInput && this.endInput) {
    //     this.searchParams['date_from'] = moment(this.startInput).format();
    //     this.searchParams['date_to'] = moment(this.endInput).format();
    //   }
    // }


    if (this.timeout) {
      clearTimeout(_this.timeout);
    }

    this.timeout = setTimeout(() => {
      _this.reportService.getRefundsList({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format(), limit: 10 }).subscribe(res => {
        res['meta']['per_page'] = +res['meta']['per_page'];
        _this.page = res['meta'];
        _this.refunds = res['data'];
        _this.rows = [];
        for (let refund of _this.refunds) {
          _this.rows.push(
            {
              id: refund.id,
              guest: refund.guest,
              first_name: refund.first_name,
              last_name: refund.last_name,
              refund_amount: refund.refund_amount,
              refund_cost: refund.refund_cost,
              is_complete: refund.is_completed,
              created_at: refund.created_at
            }
          );
        }
        if (_this.rows.length == 0) {
          //this.messageService.error("No Refunds Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        }
        console.log(_this.page);
        _this.loading.isLoading = false;
      },
        err => {
          _this.loading.isLoading = false;
          console.log(err);
        });
    }, 350);
  }

  //Company Create - Sales Graphs List of Contents


  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  completeRefund(row) {
	this.reportService.completeRefund(row.id).subscribe(res => {
		if(res['code'] == 200)
		{
			row.is_complete = true;

			this.messageService.success("Refund Marked as Complete.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
		}
	}, 
	err => {
		console.log(err);
	})
  }

  statModel: any;
  loadTotals() {
    let _this = this;
    this.reportService.getRefundReportStats({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format(), limit: 10 }).subscribe(res => {
      _this.statModel = res['data'];
    },
      err => {
        console.log(err);
      })
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    this.searchParams.page = this.currentPage;
    this.loadRefunds();
    this.setFilter("pageRefund", "" + this.currentPage);
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  onFocus(event) {
    // if (event['type'] == 'click') {
    //   let id = event['row']['id'];
    //   this.router.navigate(['/pos/' + id]);
    // }
  }

  clearFilters() {
    this.freeSearch = "";
    this.startInput = null;
    this.endInput = null;
    this.updateFilters();
  }

  setFilter(name, value) {
    if (value) {
      //localStorage.setItem(name, JSON.stringify(value));
    }
  }

  saveFilters() {
    let _this = this;
    setTimeout(() => {
      localStorage.removeItem("searchRefund");
      _this.setFilter("pageRefund", _this.currentPage);
      _this.setFilter("searchRefund", _this.freeSearch);
    }, 250);
  }

  getFilter(name): any {
    if (localStorage.getItem(name)) {
      let val = JSON.parse(localStorage.getItem(name));
      return val;
    }
    return null;
  }

  loadFilters() {
    if (this.external) {
      this.updateFilters();
      return;
    }
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.getItem(localStorage.key(i)) == "undefined" || localStorage.getItem(localStorage.key(i)) == undefined || localStorage.getItem(localStorage.key(i)) == null) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    // if (this.getFilter("dateFromRefund") && this.getFilter("dateToRefund")) {
    //   this.startInput = new Date(this.getFilter("dateFromRefund"));
    //   this.endInput = new Date(this.getFilter("dateToRefund"));
    // }
    this.freeSearch = this.getFilter("searchRefund");
    this.currentPage = (this.getFilter("pageRefund") ? this.getFilter("pageRefund") : 1);
    this.updateFilters();
  }

  filterLoadTimeout: any
  updateFilters(skip?) {
    let _this = this;
    _this.searchParams = { per_page: environment.per_page, page: 1 };
    if (!skip) {
      _this.searchParams.page = _this.currentPage;
    }
    else {
      _this.currentPage = 1;
      _this.setFilter("pageRefund", 1);
    }
    if (this.freeSearch) {
      this.searchParams['q'] = this.freeSearch;
    }

    if (this.startInput && this.endInput) {
      this.searchParams['date_from'] = moment(this.startInput).format();
      this.searchParams['date_to'] = moment(this.endInput).format();
    }

    switch (this.activePeriod) {
      case 1:
        this.startInput = moment(new Date()).subtract(1, 'd').toDate();
        this.endInput = new Date();
        break;
      case 2:
        this.startInput = moment(new Date()).add(-7, "days").startOf('day').toDate();
        this.endInput = new Date();
        break;
      case 3:
        this.startInput = moment(new Date()).add(-30, "days").startOf('day').toDate();
        this.endInput = new Date();
        break;
      //   case 4:
      //     this.startInput = moment(new Date()).subtract(1,'d').toDate();
      //     this.endInput = new Date();
      //     break;
    }

    if (this.external) {
      return;
    }

    if (this.filterLoadTimeout) {
      clearTimeout(this.filterLoadTimeout);
    }
    this.filterLoadTimeout = setTimeout(() => {
      this.loadRefunds();
      this.loadTotals();
    }, 350);
    this.saveFilters();
  }


  //List Dot Menu Bulk Selection

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  openBulk() {
    this.mdSlideUp.show()
  }

  mainToggle: boolean = false;
  activeActionTitle: string;
  menuItems: any[] = [
    { action: 'export-selected', message: 'Export Selected', class: '', icon: 'fal fa-file-export', permissions: ['view_pos'] },
    { action: 'export-pending', message: 'Export Pending', class: '', icon: 'fal fa-file-export', permissions: ['view_pos'] },
    // { action: 'export-all', message: 'Export All', class: '', icon: 'fal fa-file-export', permissions: ['view_pos'] },
  ];

  menuItems2: any[] = [
    { action: 'mark-complete', message: '', class: '', icon: 'fal fa-file-export', permissions: ['view_pos'] },
  ];

  handleButtonPress(event) {
    switch (event) {
      case "export-selected":
        this.activeActionTitle = "Export Selected";
        this.openBulk();
        break;
      case "export-all":
        this.activeActionTitle = "Export All";
        this.openBulk();
        break;
      case "export-pending":
        this.activeActionTitle = "Export Pending";
        this.openBulk();
        break;
	  case "mark-complete":
		this.activeActionTitle = "Mark Complete";
		this.openBulk();
		break;
      default:
        console.log(event);
        break;
    }
  }

  activeRows: Map<number, any> = new Map<number, any>();
  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if (row['bulk_checked']) {
      _this.activeRows.set(row['id'], row);
    }
    else {
      _this.activeRows.delete(row['id']);
    }
    this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }

  updateMenuItems() {
    for (let item of this.menuItems) {
      if (this.activeRows.size == 0) {
        if (item.action.includes('export-selected')) {
          item.Disabled = true;
        }
      }
      else {
        if (item.action.includes('export-selected')) {
          item.Disabled = false;
        }
      }
    }
    return this.menuItems;
  }

  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if (this.mainToggle == true) {
        _this.activeRows.set(item.id, item);
      }
      else {
        _this.activeRows.delete(item.id);
      }
    }
    this.updateMenuItems();
  }

  submitToggle() {
    this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), 'Refund');
    this.activeRows = new Map<number, any>();
  }

}