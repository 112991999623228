import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http : HttpClient
  ) { }

    //from/to/pos/company/event

    getRefundsList(searchParams){
      return this.http.get(`${environment.baseUrl}/report/refund`,{params:searchParams});
    }

    getTokensStats(searchParams){
      return this.http.get(`${environment.baseUrl}/report/token`,{params:searchParams});
    }

    getSalesList(searchParams){
      return this.http.get(`${environment.baseUrl}/report/sales`,{params:searchParams});
    }

    getSalesTotals(searchParams){
      return this.http.get(`${environment.baseUrl}/report/sales/totals`,{params:searchParams});
    }
    
    getTransactionGraph(searchParams){
      return this.http.get(`${environment.baseUrl}/report/sales/graph/transaction`,{params:searchParams});
    }

    getActivePos(searchParams){
      return this.http.get(`${environment.baseUrl}/report/sales/graph/activepos`,{params:searchParams});
    }

    getActivePosUser(searchParams){
      return this.http.get(`${environment.baseUrl}/report/sales/graph/activeposuser`,{params:searchParams});
    }

    getTotalBalance(searchParams){
      return this.http.get(`${environment.baseUrl}/report/token/balance`,{params:searchParams});
    }

    getRefundReportStats(searchParams){
      return this.http.get(`${environment.baseUrl}/report/refund/stats`,{params:searchParams});
    }

    completeRefund(refundID){
      return this.http.put(`${environment.baseUrl}/token/refund/request/${refundID}/complete`, {});
    }

	getXreport(datefrom, dateto){
		return this.http.get(`${environment.baseUrl}/xreport?date_from=` + datefrom+ `&date_to=`+dateto);
	  }

}