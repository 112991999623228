import { Routes } from '@angular/router';
//Layouts
import { 
  //CondensedComponent,
  BlankComponent,
  //CorporateLayout,
  SimplyWhiteLayout,
  //ExecutiveLayout,
  //CasualLayout ,
  //BlankCasualComponent,
  //BlankCorporateComponent,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';


export const AppRoutes: Routes = [

  {
    path: '',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_dashboard'
    },
    component: SimplyWhiteLayout,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_admin'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/user/user.module#UserModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'guest',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_guest'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/client/client.module#ClientModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'posuser',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_pos_user'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/pos-user/pos-user.module#POSUserModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'event',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_event'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/event/event.module#EventModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'pos',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_pos'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/pos/pos.module#POSModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'voucher',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_pos'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/voucher/voucher.module#VoucherModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'transaction',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_transaction'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/transaction/transaction.module#TransactionModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'token',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_token'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/token/token.module#TokenModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'report',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_report'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/report/report.module#ReportModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'update_admin'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/profile/profile.module#ProfileModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_dashboard'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/dashboard/dashboard.module#DashboardModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'session',
    data: {
      permission:'view_dashboard'
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  {
    path: 'company',
    data: {
      permission:'view_company'
    },
    canActivate: [AuthGaurd,RoleGuard],
    component: SimplyWhiteLayout,
    loadChildren: "./view/company/company.module#CompanyModule",
    runGuardsAndResolvers: 'always'
  },
];
