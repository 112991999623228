import { formatDate } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Utils } from '../../../shared/services/utils.service';
import { ReportService } from "../../../shared/services/report.service";
import { FormGroup } from "@angular/forms";
import { ReportModel } from "../../../shared/models/report.model";
import { ActivatedRoute, Router } from "@angular/router";
import { POSService } from "../../../shared/services/pos.service";
import { POSUserService } from "../../../shared/services/pos-user.service";
import { ClientService } from "../../../shared/services/client.service";
import { EventService } from "../../../shared/services/event.service";
import { TokenService } from "../../../shared/services/token.service";
import { AuthService } from "../../../shared/services/auth.service";
import { MessageService } from "../../../@pages/components/message/message.service";
import { TransactionModel } from "../../../shared/models/transaction.model";
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from "../../../shared/models/page.model";
import { environment } from "../../../../environments/environment";

@Component({
	selector: 'app-report-xreport-statistics',
	templateUrl: './report-xreport.component.html',
	styleUrls: ['./report-xreport.component.scss']
})
export class XReportComponent implements OnInit {
	date = new Date();
	startdate = this.date.setDate(this.date.getDate());
	start = formatDate(this.startdate, 'yyyy-MM-dd', 'en');
	enddate = this.date.setDate(this.date.getDate());
	end = formatDate(this.enddate, 'yyyy-MM-dd', 'en');
	calstart = formatDate(this.startdate, 'yyyy-MM-ddT00:00', 'en');
	calend = formatDate(this.enddate, 'yyyy-MM-ddT23:59', 'en');
	test = [];
	//user: UsersModel[] = [];
	form: FormGroup;
	_rep: ReportModel[] = [];
	vf = '';
	vt = '';

	/////

	@Input() external: boolean = false;
	@Input() activeToken: number;
	@Input() activePos: number;
	@Input() activeEvent: number;
	@ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

	freeSearch: string;
	transactions: TransactionModel[];
	ColumnMode = ColumnMode;
	page: Page = new Page();
	currentPage = 1;
	columns = [];
	rows = [];
	zreport_date: Date = new Date();
	total_tips = 0;
	total_topups = 0;
	total_sales = 0;

	searchParams = { per_page: environment.per_page, page: this.currentPage };

	constructor(
		private reportService: ReportService,
		private utilsService: Utils,
		private router: Router,
		private posService: POSService,
		private posUserService: POSUserService,
		private guestService: ClientService,
		private eventService: EventService,
		private tokenService: TokenService,
		private authService: AuthService,
		private messageService: MessageService,
		private route: ActivatedRoute,
	) {
	}
	ngOnInit() {
		this.createForm({});
		this.CalenderDate();
		// this.columns = [
		// 	{ name: 'ID', flexGrow: 1, sortable: true, cellClass: 'report_id', headerClass: 'report_id' },
		// 	{ name: 'token', flexGrow: 3, sortable: true, cellClass: 'report_token', headerClass: 'report_token' },
		// 	{ name: 'guest', flexGrow: 1, sortable: true, cellClass: 'report_guest', headerClass: 'report_guest' },
		// 	{ name: 'pos', flexGrow: 1, sortable: true, cellClass: 'report_pos', headerClass: 'report_pos' },
		// 	{ name: 'date', flexGrow: 3, sortable: true, cellClass: 'report_date', headerClass: 'report_date' },
		// 	{ name: 'amount', flexGrow: 1, sortable: true, cellClass: 'report_amount', headerClass: 'report_amount' }
		//   ];

		this.columns = [
			{ name: 'Name', flexGrow: 1, sortable: true, cellClass: 'Transaction_name', headerClass: 'Transaction_name' },
			{ name: 'Email', flexGrow: 3, sortable: true, cellClass: 'Transaction_email', headerClass: 'Transaction_email' }
		];
	}

	getDates() {
		this.vf = ((<HTMLInputElement>document.getElementById("validfrom")).value) + ':00Z';
		this.vt = ((<HTMLInputElement>document.getElementById("validto")).value) + ':00Z';
		console.log(this.vf);
		console.log(this.vt);
		
		if (this.vf > this.vt) {
			this.messageService.error("Incorrect Date", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
			return;
		}

		this.reportService.getXreport(this.vf, this.vt).subscribe(res => {
			console.log(res['data']);

			this.total_tips = res['data']['total_tips'];
			this.total_topups = res['data']['total_topups'];
			this.total_sales = res['data']['total_sales'];
			this.tbu_user = res['data']['topups_by_users']
			this.tbu_type = res['data']['topups_by_type']
			this.sales_users = res['data']['sales_by_users']
			this.tips_users = res['data']['tips_by_users']


		});

		// this.getTBU();
		// this.getTBT();
		// this.getSBU();
		// this.getTipsBU();
		this.currentPage = 1;
		this.loadTransactionsPOS();

	}
	CalenderDate() {
		this.vf = this.calstart + ':00Z';
		this.vt = this.calend + ':00Z';
		console.log('this.vt')
		this.reportService.getXreport(this.vf, this.vt).subscribe(res => {
			console.log(res['data']);

		});

	}

	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = ReportModel.getFormGroup(input);
	}

	tbu_user = []
	getTBU() {
		console.log(this._rep);
		this.reportService.getXreport(this.vf, this.vt).subscribe(res => {
			console.log(res['data']['topups_by_users']);

			console.log(this.tbu_user);

		});
	}

	tbu_type = []
	getTBT() {
		console.log(this._rep);
		this.reportService.getXreport(this.vf, this.vt).subscribe(res => {
			console.log(res['data']['topups_by_type']);

			console.log(this.tbu_type);

		});
	}

	sales_users = []
	getSBU() {
		console.log(this._rep);
		this.reportService.getXreport(this.vf, this.vt).subscribe(res => {
			console.log(res['data']['sales_by_users']);

			console.log(this.sales_users);

		});
	}

	tips_users = []
	getTipsBU() {
		console.log(this._rep);
		this.reportService.getXreport(this.vf, this.vt).subscribe(res => {
			console.log(res['data']['tips_by_users']);

			console.log(this.tips_users);

		});
	}

	getClasses(row) {
		if (row.amount > 0 && !row.is_tip) {
			return "fa-arrow-up green";
		}
		else if (row.amount < 0 && !row.is_tip) {
			return "fa-arrow-down red";
		}
		else if (row.is_tip) {
			return "fa-coins red";
		}
		return;
	}
	isLoading: boolean = false;


	loadTransactionsPOS() {
		let _this = this;
		_this.transactions = [];
		if (!_this.loading.isFirstLoad) {
			_this.loading.isFirstLoad = true;
			_this.loading.isLoading = true;
		}
		this.reportService.getSalesList({date_from: this.vf, date_to: this.vt, page: this.currentPage }).subscribe(res => {
			res['meta']['per_page'] = +res['meta']['per_page'];
			_this.page = res['meta'];
			_this.transactions = res['data'];
			_this.rows = [];
			for (let transaction of _this.transactions) {
				_this.rows.push(
					{
						id: transaction.id,
						created_at: transaction.created_at,
						created_by: (transaction.pos_user ? transaction.pos_user : transaction.admin),
						guest: transaction.guest,
						amount: transaction.amount,
						description: transaction.description,
						is_tip: transaction.is_tip,
						pos: transaction.pos,
						payment_type: transaction.type.title,
						token: transaction.token
					}
				);
			}
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err);
			});
	}

	setPage(pageInfo) {
		this.currentPage = pageInfo.offset + 1;
		this.searchParams.page = this.currentPage;
		this.loadTransactionsPOS();
		// this.setFilter("pageTransaction", "" + this.currentPage);
	  }
	



	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	onFocus(event) {
		if (event['type'] == 'click') {
			let id = event['row']['id'];
			this.router.navigate(['/transaction/' + id]);
		}
	}


}