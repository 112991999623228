import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/token`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/token/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/token/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/token`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/token/`+id);
    }

    topup(id,params){
      return this.http.post(`${environment.baseUrl}/token/${id}/topup`,params);
    }

    charge(id,params){
      return this.http.post(`${environment.baseUrl}/token/${id}/charge`,params);
    }

    transfer(oldId,id){
      return this.http.post(`${environment.baseUrl}/token/transfer`,{old_token:oldId,new_token:id});
    }

    getTypes(){
      return this.http.get(`${environment.baseUrl}/token/type`);
    }

    block(id){
      return this.http.post(`${environment.baseUrl}/token/`+id+`/block`,{});
    }

    unblock(id){
      return this.http.post(`${environment.baseUrl}/token/`+id+`/unblock`,{});
    }

    activate(id,date){
      return this.http.post(`${environment.baseUrl}/token/`+id+`/activate`,{expiry_date:date});
    }

    linkEvents(id,events){
      return this.http.post(`${environment.baseUrl}/token/`+id+`/event`,{events:events});
    }

    getTransactions(id){
      return this.http.get(`${environment.baseUrl}/token/${id}/transaction`);
    }

    getLinkedEvents(id){
      return this.http.get(`${environment.baseUrl}/token/`+id+`/event`);
    }

    getTokenSpendingData(id){
      return this.http.get(`${environment.baseUrl}/token/`+id+`/totalspend`);
    }

    ///token/{id}/totalspend

}