import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../../@pages/components/select/select.module';
import { MessageModule } from '../../../@pages/components/message/message.module';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DotMenuComponent } from './dot-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    //SharedModule,
    BsDropdownModule,
    NgxDatatableModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
    TextMaskModule,
    pgSwitchModule,
    NgxPermissionsModule
    
  ],
  declarations: [DotMenuComponent],
  exports: [DotMenuComponent],
  providers:[MessageService]
})
export class DotMenuModule { }
