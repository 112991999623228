import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as FileSaver from "file-saver";
import * as moment from 'moment';

@Injectable({
  providedIn: "root",
})
export class Utils {

  //public didFirstLoad:boolean = false;
  onDelete:EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpClient) {}

  filterArrayByIds(idList: number[], array: any[]) {
    let filteredArray = array.filter((item) => !idList.includes(item.id));
    let mapValues: Map<number, any> = new Map<number, any>();
    for (let val of filteredArray) {
      mapValues.set(val.id, val);
    }
    return Array.from(mapValues.values());
  }

  filteredParams: any = {};
  filterUpdateTimeout: any;
  pushFiltersToRouter(value, name, filteredParams): EventEmitter<any> {
    let pusher = new EventEmitter<any>();
    let _this = this;
    this.filteredParams = filteredParams;
    value = encodeURIComponent(value);
    let paramStream = "?";
    for (let param in _this.filteredParams) {
      if (
        !_this.filteredParams[param] ||
        _this.filteredParams[param] == "null"
      ) {
        continue;
      }
      paramStream += param + "=" + _this.filteredParams[param] + "&";
    }
    if (_this.filterUpdateTimeout) {
      clearTimeout(_this.filterUpdateTimeout);
      _this.filterUpdateTimeout = null;
    }
    _this.filterUpdateTimeout = setTimeout(() => {
      pusher.emit(paramStream);
      //window.history.pushState(null,"",("transaction"+paramStream));
      clearTimeout(_this.filterUpdateTimeout);
      _this.filterUpdateTimeout = null;
    }, 500);
    return pusher;
  }

  getFormattedPeriod(startInput: Date, endInput: Date): string {
    if (moment(startInput).isSame(endInput, "year")) {
      if (moment(startInput).isSame(endInput, "M")) {
        if(moment(startInput).isSame(endInput, "D")){
          return (
            moment(endInput).format("DD MMMM YYYY")
          );
        }
        else{
          return (
            moment(startInput).format("DD") +
            " - " +
            moment(endInput).format("DD MMMM YYYY")
          );
        }
      } else {
        return (
          moment(startInput).format("DD MMMM") +
          " - " +
          moment(endInput).format("DD MMMM YYYY")
        );
      }
    } else {
      if (moment(startInput).isSame(endInput, "M")) {
        return (
          moment(startInput).format("DD") +
          " - " +
          moment(endInput).format("DD MMMM YYYY")
        );
      } else {
        let dateStart = moment(startInput).format("DD MMMM YYYY");
        let dateEnd = moment(endInput).format("DD MMMM YYYY");
        return dateStart + " - " + dateEnd;
      }
    }
  }

  formatNumbersToFixedWithCommas(number) {
    var amount = parseFloat(number).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  }

  exportTableDownload(table, params) {
    table = table.toLowerCase();
    this.http
      .post(`${environment.baseUrl}/` + table + `/export`, params)
      .subscribe(
        (res) => {
          let f = res["data"];
          let csv = res["meta"]["columns"].join(",") + "\n";
          for (let row in f) {
            var rowArr = [];
            for (let key in f[row]) {
              rowArr.push(f[row][key]);
            }
            csv += rowArr.join(",") + "\n";
          }
          console.log(csv);
          const blob = new Blob([csv], { type: "text/plain" });
          console.log(f);
          FileSaver.saveAs(blob, table + ".csv");
        },
        (err) => {
          console.log(err);
        }
      );
  }

  submitToggle(
    activeActionTitle,
    messageService,
    searchParams,
    rows,
    tableName,
    deleteService?
  ) {
    let _this = this;
    switch (activeActionTitle) {
      case "Export Selected":
        messageService.remove();
        messageService.success("Exported Selected Records!", {
          Position: "bottom-right",
          Style: "simple",
          Duration: 5000,
        });
        let recordIds: number[] = [];
        console.log(rows);
        for (let row of rows) {
          recordIds.push(row.id);
        }
        let params = { ...searchParams, list_all: false, records: recordIds };
        this.exportTableDownload(tableName, params);
        break;
        
        case "Delete Selected":
          messageService.remove();
          messageService.success("Deleted Selected Records!", {
            Position: "bottom-right",
            Style: "simple",
            Duration: 5000,
          });
          let recordIdsDelete: number[] = [];
          console.log(rows);
          for (let row of rows) {
            if(deleteService){
              deleteService.delete(row.id).subscribe(res => {
                console.log(res);
              },
              err => {
                console.log(err);
              });
            }
          }
          this.onDelete.emit(true);
          break;

      case "Export All":
        messageService.remove();
        this.exportTableDownload(tableName, {
          ...searchParams,
          list_all: true,
          records: [],
        });
        messageService.success("Exported All Records!", {
          Position: "bottom-right",
          Style: "simple",
          Duration: 5000,
        });
        break;

        case "Export Pending":
        messageService.remove();
        this.exportTableDownload(tableName, {
          ...searchParams,
          is_completed: false,
          list_all: true,
          records: [],
        });
        messageService.success("Exported Pending Records!", {
          Position: "bottom-right",
          Style: "simple",
          Duration: 5000,
        });
        break;
      default:
        messageService.error("Please select a bulk action!", {
          Position: "bottom-right",
          Style: "simple",
          Duration: 5000,
        });
        break;
    }
  }

  generateGraphData(data, colorList, graphType) {
    let _this = this;
    let chart: any;
    let formattedData = {
      names: [],
      data: [],
      sub_totals: [],
      vat_totals: [],
    };

    switch (graphType) {
      case "pie":
        for (let r of data) {
          formattedData.names.push(r["title"]);
          formattedData.data.push({
            value: r["value"], //.toFixed(2),
            name: r["title"],
          });
        }
        break;
      case "stacked-bar":
        for (let r of data) {
          formattedData.names.push(r["title"]);
          formattedData.data.push(r["value"].toFixed(2));
          formattedData.sub_totals.push(r["subtotal"].toFixed(2));
          formattedData.vat_totals.push(r["vattotal"].toFixed(2));
        }
        break;
      case "bar":
        let c = 0;
        for (let r of data) {
          // let randomVal = Math.floor(Math.random() * 10);
          // let generatedColor = colorList[randomVal];
          formattedData.data.push({
            value: r["value"], //.toFixed(2),
            itemStyle: { color: colorList[c] },
          });
          formattedData.names.push(r["title"]);
          c++;
        }
        break;
    }

    chart = {
      color: colorList,
      // tooltip: {
      //   trigger: 'axis',
      //   axisPointer: {
      //     type: 'cross',
      //     label: {
      //       backgroundColor: '#6a7985'
      //     }
      //   }
      // },
      legend: {
        data: ["Total", "Subtotal", "VAT"],
      },
      toolbox: {
        feature: {},
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: formattedData.names,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
    };

    switch (graphType) {
      case "bar":
        chart["tooltip"] = {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}",
        };
        chart["xAxis"] = {
          type: "category",
          data: formattedData.names,
          axisLabel: {
            type: "value",
          },
          // {
          //   formatter: function (e) {
          //     let split: string[] = e.toString().split(" ");
          //     let formattedValue = (split[0] + "." + split[1][0].toUpperCase());
          //     return formattedValue;
          //   },
          //   rotate: 0
          // }
        };
        chart["yAxis"] = {
          type: "value",
        };
        chart["series"] = [
          {
            data: formattedData.data,
            name: "Total Sales",
            type: "bar",
          },
        ];
        break;
      case "stacked-bar":
        chart["tooltip"] = {
          trigger: "item",
          formatter: "{a} <br/>{b} : €{c}",
        };
        chart["xAxis"] = [
          {
            type: "category",
            data: formattedData.names,
          },
        ];
        chart["yAxis"] = [
          {
            type: "value",
          },
        ];
        chart["series"] = [
          {
            symbolSize: 10,
            name: "Subtotal",
            type: "bar",
            stack: "3",
            areaStyle: {},
            z: "2",
            barGap: "-100%",
            data: formattedData.sub_totals,
          },
          {
            symbolSize: 10,
            name: "VAT",
            type: "bar",
            stack: "1",
            barGap: "-100%",
            z: "3",
            areaStyle: {},
            data: formattedData.vat_totals,
          },
          {
            symbolSize: 10,
            name: "Total",
            type: "bar",
            stack: "2",
            z: "1",
            label: {
              normal: {
                position: "top",
              },
            },
            barGap: "-100%",
            areaStyle: {},
            data: formattedData.data,
          },
        ];
        break;
      case "pie":
        chart["tooltip"] = {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        };
        chart["series"] = [
          {
            name: "% Of Transactions",
            type: "pie",
            radius: "70%",
            center: ["50%", "60%"],
            data: formattedData.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ];
        chart["legend"] = {
          orient: "vertical",
          left: "left",
          data: formattedData.names,
        };
        delete chart["xAxis"];
        delete chart["yAxis"];
        delete chart["grid"];
        break;
    }
    return chart;
  }
}
