import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { LocalStoreService } from '../services/local-store.service';
import { MessageService } from '../../@pages/components/message/message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private store : LocalStoreService,
    private messageService : MessageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.auth.signout();
        //debugger;
      }

      if (err.status === 403) {
        this.messageService.remove();
        this.messageService.error("Unauthorized.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        return throwError(err.error.errors);
      }

      if (err.status === 404) {
        setTimeout(() => {
          this.messageService.remove();
          this.messageService.error("Not Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        }, 500);
        return throwError(err.error.errors);
      }

      if (err.status === 500) {
        setTimeout(() => {
          this.messageService.remove();
          this.messageService.error("Something went wrong.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        }, 500);
        return throwError(err.error.errors);
      }



      // const error = err.error.message || err.statusText;
      return throwError(err.error.errors);
    }));
  }
}