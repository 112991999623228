import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Type } from '@angular/core';
import { Constants, Types } from '../../../../constants';
import { MessageContainerComponent } from './message-container.component';
import { MessageData, MessageDataFilled, MessageDataOptions } from './message.definitions';

// TODO: remove MessageData generic type as it has no contributon in typing
export class MessageBaseService<ContainerClass extends MessageContainerComponent, MessageData> {
  protected _counter = 0; // Id counter for messages
  protected _container: ContainerClass;

  constructor(private overlay: Overlay, private containerClass: Type<ContainerClass>, private _idPrefix: string = '') {
    //Wait till wrapper gets init parameters
    setTimeout(() => {
      this._container = overlay.create().attach(new ComponentPortal(containerClass)).instance;
    }, 100);
  }

  remove(messageId?: string): void {
    if (messageId) {
      this._container.removeMessage(messageId);
    } else {
      this._container.removeMessageAll();
    }
  }

  createMessage(message: object, options?: MessageDataOptions): MessageDataFilled {
    // TODO: spread on literal has been disallow on latest proposal
    const resultMessage: MessageDataFilled = {
      ...message,
      ...{
        messageId: this._generateMessageId(),
        options,
        createdAt: new Date()
      }
    };
    //this._container.removeMessageAll();
    this._container.createMessage(resultMessage);

    return resultMessage;
  }

  protected _generateMessageId(): string {
    return this._idPrefix + this._counter++;
  }
}

export interface ActionModel {
    onAccept?: {
      function?: Function, 
      buttonText?: string 
    };
    onDecline?: { 
      function?: Function, 
      buttonText?: string
    },
    onIgnore?: { 
      function?: Function, 
      buttonText?: string
    }
}

@Injectable()
export class MessageService extends MessageBaseService<MessageContainerComponent, MessageData> {
  constructor(overlay: Overlay) {
    super(overlay, MessageContainerComponent, 'message-');
  }

  sendNotification(message:string,type:string,actions:ActionModel,durationOverride?) {
    let duration = durationOverride ? durationOverride : 3000;
    let buttonOptions = {
      Type: Types.warning,
      Style: 'simple',
      Duration: 0,
      Actions: actions,
    };
    let colorSettings;
    switch (type) {
      case Types.warning:
        colorSettings = { Color: Constants.warning, FontColor: Constants.warningText };
        this.createMessage({ type: 'success', content:message }, { ...buttonOptions, ColorSettings: colorSettings, Type: Types.warning, Duration: duration });
        break;
      case Types.indicator:
        colorSettings = { Color: Constants.success, FontColor: Constants.successText };
        this.createMessage({ type: 'success', content:message }, { ...buttonOptions, ColorSettings: colorSettings, Type: Types.indicator, Duration: duration });
        break;
      case Types.intervention:
        colorSettings = { Color: Constants.error, FontColor: Constants.errorText };
        if(message == 'You may have unsaved data. Are you sure you\'d like to discard it?'){
          colorSettings['Color'] = Constants.white;
        }
        this.createMessage({ type: 'success', content:message }, { ...buttonOptions, ColorSettings: colorSettings, Type: Types.intervention, Duration: duration });
        break;
    }
  }

  // Shortcut methods
  success(content: string, options?: MessageDataOptions): MessageDataFilled {
    let buttonOptions = {
      Type: Types.warning,
      Style: 'simple',
      Duration: 0
    };
    let colorSettings = { Color: Constants.success, FontColor: Constants.successText };
    return this.createMessage({ type: 'success', content:content }, { ...buttonOptions, ColorSettings: colorSettings, Type: Types.indicator, Duration: 5000 });
  }

  error(content: string, options?: MessageDataOptions): MessageDataFilled {
    let buttonOptions = {
      Type: Types.warning,
      Style: 'simple',
      Duration: 0
    };
    let colorSettings = { Color: Constants.error, FontColor: Constants.errorText };
    return this.createMessage({ type: 'error', content:content }, { ...buttonOptions, ColorSettings: colorSettings, Type: Types.indicator, Duration: 5000 });
  }
}
