import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { EChartOption } from 'echarts';
import { isDate } from 'util';
import { ReportService } from '../../../shared/services/report.service';
import { Utils } from '../../../shared/services/utils.service';
import { TransactionListComponent } from '../../transaction/transaction-list/transaction-list.component';
import { EventService } from '../../../shared/services/event.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { POSUserService } from '../../../shared/services/pos-user.service';
import { pgTimePickerComponent } from '../../../@pages/components/time-picker/timepicker.component';


@Component({
    selector: 'app-report-sales-statistics',
    templateUrl: './report-sales.component.html',
    styleUrls: ['./report-sales.component.scss']
})
export class SalesComponent implements OnInit {

    transactionChart: EChartOption;
    posChart: EChartOption;
    posUserChart: EChartOption;
    statModel: any;
    labelOption = {
        show: true,
        rotate: 90,
        formatter: '{a.char(0)}',
        fontSize: 16
    };
    periodsMap: Map<number, any> = new Map<number, any>();
    periods: any[] = [{ id: 1, title: "Last 24 Hours" }, { id: 2, title: "Last Week" }, { id: 3, title: "Last Month" }, { id: 4, title: 'Custom Range' }];
    activePeriod: number = 1;
    timeout: any;
    @ViewChild('transactionList', { static: true }) transactionComponent: TransactionListComponent

    barData = [{ "title": "Mike Oxmaul", "value": 33873.96 }, { "title": "Tricia Bonnet", "value": 14500.23 }, { "title": "New Client", "value": 5600.14 }, { "title": "Yuri Borasov", "value": 100 }, { "title": "John Borg", "value": 0 }, { "title": "Nicholas Gatt", "value": 0 }, { "title": "Raymond Andolini", "value": 0 }, { "title": "A B", "value": 0 }, { "title": "Sam Winchester", "value": 0 }, { "title": "Fred Borg", "value": 0 }];
    lineData = [
        { "title": "Front Desk", "value": 1260, "subtotal": 1200, "vattotal": 60 },
        { "title": "Main Bar", "value": 2600, "subtotal": 2550, "vattotal": 50 },
        { "title": "Pool Bar", "value": 1360, "subtotal": 1160, "vattotal": 200 },
        { "title": "Buffet Restaurant", "value": 500, "subtotal": 450, "vattotal": 50 },
        { "title": "Alacarte Restaurant", "value": 900, "subtotal": 800, "vattotal": 100 }];
    pieData = [{ "title": "Ren Men", "value": 56 }, { "title": "Rain Man", "value": 46 }, { "title": "Daniel Micallef", "value": 24 }, { "title": "Christain Grech", "value": 33 }, { "title": "Ray Dalipi", "value": 19 }, { "title": "test testt", "value": 25 }];

    constructor(
        private reportService: ReportService,
        private utilsService: Utils,
        private eventService: EventService,
        private posUserService : POSUserService,
        private messageService: MessageService
    ) {
    }

    startInput: Date;
    endInput: Date;
    eventFilter: number;
    posUserFilter: number;

    setDateRange(event) {
        let dates: Date[] = event;
        this.startInput = dates[0];
        this.endInput = dates[1];
        this.updateFilters();
    }

    updateFilters() {
        switch (this.activePeriod) {
            case 1:
                this.startInput = moment(new Date()).subtract(1, 'd').toDate();
                this.endInput = new Date();
                break;
            case 2:
                this.startInput = moment(new Date()).add(-7, "days").startOf('day').toDate();
                this.endInput = new Date();
                break;
            case 3:
                this.startInput = moment(new Date()).add(-30, "days").startOf('day').toDate();
                this.endInput = new Date();
                break;
            //   case 4:
            //     this.startInput = moment(new Date()).subtract(1,'d').toDate();
            //     this.endInput = new Date();
            //     break;
        }
        this.loadByDate();
    }

    incomingPaginatedSelectValue(fieldName, value) {
        let _this = this;
        switch (fieldName) {
            case 'event':
                _this.eventFilter = value;
                break;
            case 'pos-user':
                _this.posUserFilter = value;
                break;
        }
        this.updateFilters();
    }

    loadTotals(param) {
        let _this = this;
        this.reportService.getSalesTotals(param).subscribe(res => {
            _this.statModel = res['data'];
        },
            err => {
                console.log(err);
            })
    }

    loadTransactionChart(param) {
        let _this = this;
        this.reportService.getTransactionGraph(param).subscribe(res => {
            _this.transactionChart = _this.utilsService.generateGraphData(res['data'], _this.colorList, 'bar');
            if (res['data'].length == 0) {
                _this.transactionChart = null;
                //this.messageService.error("No Transactions Data Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
            }
        },
            err => {
                console.log(err);
            });
    }

    loadPosChart(param) {
        let _this = this;
        console.log(_this.posChart);
        this.reportService.getActivePos(param).subscribe(res => {
            _this.posChart = _this.utilsService.generateGraphData(res['data'], _this.colorList, 'bar');
            if (res['data'].length == 0) {
                _this.posChart = null;
                //this.messageService.error("No POS Device Data Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
            }
        },
            err => {
                console.log(err);
            });
    }

    loadPosUserChart(param) {
        let _this = this;
        this.reportService.getActivePosUser(param).subscribe(res => {
            _this.posUserChart = _this.utilsService.generateGraphData(res['data'], _this.colorList, 'pie');
            if (res['data'].length == 0) {
                _this.posUserChart = null;
                //this.messageService.error("No POS User Data Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
            }
        },
            err => {
                console.log(err);
            });
    }

    colorList: any[] = ['#aa0063', '#1E475B', '#ff1744', '#37d5a8', '#5272b3', '#FFd945', '#7252D3', '#bc37c7', '#4865e6', '#e97500'];
    isLoading: boolean = false;

    ngOnInit() {
        let _this = this;
        this.updateFilters();
        for (let v of this.periods) {
            this.periodsMap.set(v.id, v.title);
        }
        // for (let i = 0; i < 10; i++) {
        //     _this.colorList.push(_this.getRandomColor());
        // }
    }

    loadByDate() {
        let _this = this;
        if (isDate(this.startInput) && isDate(this.endInput)) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                let param = { date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format(), limit: 10 };
                if (_this.eventFilter) {
                    param['event'] = this.eventFilter;
                }
                if (_this.posUserFilter) {
                    param['pos_user'] = this.posUserFilter;
                }
                this.messageService.remove();
                this.loadTransactionChart(param);
                this.loadPosUserChart(param);
                this.loadPosChart(param);
                this.loadTotals(param);
                _this.transactionComponent.loadTransactions();
            }, 350);
        }
    }

    getPeriodName(): string {
        return this.utilsService.getFormattedPeriod(this.startInput, this.endInput);
    }

    isToday(date) {
        if (moment(date).isSame(new Date(), 'date')) {
            return true;
        } else {
            return false;
        }
    }

}
