import { Injectable } from '@angular/core';

@Injectable()
export class RoleService {
  private roles: any = {
    1: {
      role: 'Super Administrator',
      defaultRoute: '/dashboard'
    },
    2: {
      role: 'Administrator',
      defaultRoute: '/dashboard'
    },
    3: {
      role: 'Employee',
      defaultRoute: '/dashboard'
    }
  };

  constructor() { }

  getDefaultRoute(role: number): string{
    return this.roles[role].defaultRoute;
  }
}
