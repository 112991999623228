import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/event`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/event/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/event/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/event`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/event/`+id);
    }

    linkTokens(id,tokens){
      return this.http.post(`${environment.baseUrl}/event/`+id+`/token`,{tokens:tokens});
    }

    getLinkedTokens(id){
      return this.http.get(`${environment.baseUrl}/event/`+id+`/token`);
    }

    deleteTokenLink(eventId,tokenId){
      return this.http.delete(`${environment.baseUrl}/event/${eventId}/token/${tokenId}`);
    }

}