import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from '../../../shared/models/page.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { Utils } from '../../../shared/services/utils.service';
import { TransactionModel } from '../../../shared/models/transaction.model';
import { POSService } from '../../../shared/services/pos.service';
import { POSUserService } from '../../../shared/services/pos-user.service';
import { EventService } from '../../../shared/services/event.service';
import { ClientService } from '../../../shared/services/client.service';
import * as moment from 'moment';
import { ReportService } from '../../../shared/services/report.service';
import { TokenService } from '../../../shared/services/token.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit {


  @Input() external: boolean = false;
  @Input() activeToken: number;
  @Input() activePos: number;
  @Input() activeEvent: number;
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  freeSearch: string;
  transactions: TransactionModel[];
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];
  zreport_date:Date = new Date();

  searchParams = { per_page: environment.per_page, page: this.currentPage };

  constructor(
    private router: Router,
    private reportService: ReportService,
    private posService: POSService,
    private posUserService: POSUserService,
    private guestService: ClientService,
    private eventService: EventService,
    private tokenService : TokenService,
    private authService: AuthService,
    private messageService: MessageService,
    private utils: Utils,
    private route : ActivatedRoute,
    //private cdr : ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    if(this.activeToken){
      this.loadTransactionsToken(); 
    }
    else if(this.activePos){
      this.loadTransactionsPOS(); 
    }
    else{
      this.loadFilters();
    }
    this.columns = [
      { name: 'Name', flexGrow: 1, sortable: true, cellClass: 'Transaction_name', headerClass: 'Transaction_name' },
      { name: 'Email', flexGrow: 3, sortable: true, cellClass: 'Transaction_email', headerClass: 'Transaction_email' }
    ];
    this.updateMenuItems();
  }

  getClasses(row){
    if(row.amount > 0 && !row.is_tip){
      return "fa-arrow-up green";
    }
    else if(row.amount < 0 && !row.is_tip){
      return "fa-arrow-down red";
    }
    else if(row.is_tip){
      return "fa-coins red";
    }
    return;
  }
  isLoading:boolean = false;
  loadTransactionsToken(){
    let _this = this;
    _this.transactions = [];
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    _this.isLoading = true;
    this.tokenService.getTransactions(this.activeToken).subscribe(res => {
      res['meta']['per_page'] = +res['meta']['per_page'];
      _this.page = res['meta'];
      _this.transactions = res['data'];
      _this.rows = [];
      for (let transaction of _this.transactions) {
        _this.rows.push(
          {
            id: transaction.id,
            created_at: transaction.created_at,
            created_by: (transaction.pos_user ? transaction.pos_user : transaction.admin),
            description: transaction.description,
            guest: transaction.guest,
            is_tip: transaction.is_tip,
            amount: transaction.amount,
            pos: transaction.pos
          }
        );
        _this.isLoading = false;
      }
      if(_this.rows.length == 0){
        //this.messageService.error("No Transactions Found for Token.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }
      console.log(_this.page);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        _this.isLoading = false;
        console.log(err);
      });
  }

  loadTransactionsPOS(){
    let _this = this;
    _this.transactions = [];
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    this.posService.getTransactions(this.activePos,moment(this.zreport_date).format()).subscribe(res => {
      res['meta']['per_page'] = +res['meta']['per_page'];
      _this.page = res['meta'];
      _this.transactions = res['data'];
      _this.rows = [];
      for (let transaction of _this.transactions) {
        _this.rows.push(
          {
            id: transaction.id,
            created_at: transaction.created_at,
            created_by: (transaction.pos_user ? transaction.pos_user : transaction.admin),
            guest: transaction.guest,
            description: transaction.description,
            is_tip: transaction.is_tip,
            amount: transaction.amount,
            pos: transaction.pos,
            token: transaction.token
          }
        );
      }
      if(_this.rows.length == 0){
        //this.messageService.error("No Transactions Found for POS.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }
      console.log(_this.page);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  onSort(event) {
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    if(this.activeToken){
      this.loadTransactionsToken(); 
    }
    else if(this.activePos){
      this.loadTransactionsPOS(); 
    }
    else{
      this.loadTransactions();
    }
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  loadTransactions() {
    let _this = this;
    console.log("Loading Transactions!");
    if(_this.activeToken || _this.activePos){
      return;
    }
    _this.transactions = [];
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    this.isLoading = true;
    if(this.external && !this.activeToken){
      if (this.startInput && this.endInput) {
        this.searchParams['date_from'] = moment(this.startInput).format();
        this.searchParams['date_to'] = moment(this.endInput).format();
      }
    }
    
    if(this.activeEvent){
      this.searchParams['event'] = this.activeEvent;
    }
    else{
      if(this.searchParams && this.searchParams['event']){
        delete this.searchParams['event'];
      }
    }

    this.reportService.getSalesList(this.searchParams).subscribe(res => {
      res['meta']['per_page'] = +res['meta']['per_page'];
      _this.page = res['meta'];
      _this.transactions = res['data'];
      _this.rows = [];
      for (let transaction of _this.transactions) {
        _this.rows.push(
          {
            id: transaction.id,
            created_at: transaction.created_at,
            created_by: (transaction.pos_user ? transaction.pos_user : transaction.admin),
            guest: transaction.guest,
            amount: transaction.amount,
            description: transaction.description,
            is_tip: transaction.is_tip,
            pos: transaction.pos,
            token: transaction.token
          }
        );
      }
      if(_this.rows.length == 0){
        //this.messageService.error("No Transactions Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }
      console.log(_this.rows);
      _this.loading.isLoading = false;
      _this.isLoading = false;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    },
    err => {
        _this.loading.isLoading = false;
        _this.isLoading = false;
        console.log(err);
    });
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    this.searchParams.page = this.currentPage;
    this.loadTransactions();
    this.setFilter("pageTransaction", "" + this.currentPage);
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  onFocus(event) {
    if (event['type'] == 'click') {
      let id = event['row']['id'];
      //this.router.navigate(['/transaction/' + id]);
    }
  }

  clearFilterEvent:EventEmitter<any> = new EventEmitter<any>();
  clearFilters() {
    this.freeSearch = "";
    this.posFilter = null;
    this.startInput = null;
    this.endInput = null;
    this.posUserFilter = null;
    this.eventFilter = null;
    this.guestFilter = null;
    this.clearFilterEvent.emit({type:'clear'});
    this.updateFilters();
  }

  filteredParams:any = {};
  filterUpdateTimeout:any;
  setFilter(name, value) {
    let _this = this;
    _this.filteredParams = {..._this.filteredParams,[name]:value};
    this.utils.pushFiltersToRouter(value,name,_this.filteredParams).subscribe(res => {
      window.history.pushState(null,"",("transaction"+res));
    });
  }

  getFilter(name): any {
    let value = this.route.snapshot.queryParams[name];
    if(isNaN(+value)){
      return value;
    }
    return +value;
  }

  saveFilters() {
    let _this = this;
    setTimeout(() => {
      _this.setFilter("pageTransaction", _this.currentPage);
      _this.setFilter("searchTransaction", _this.freeSearch);
      _this.setFilter("posTransaction", _this.posFilter);
      _this.setFilter("posUserTransaction", _this.posUserFilter);
      _this.setFilter("eventTransaction", _this.eventFilter);
      _this.setFilter("guestTransaction", _this.guestFilter);
      if (_this.startInput && _this.endInput) {
        _this.setFilter("dateFromTransaction", moment(_this.startInput).valueOf());
        _this.setFilter("dateToTransaction", moment(_this.endInput).valueOf());
      }
      else{
        _this.setFilter("dateFromTransaction", null);
        _this.setFilter("dateToTransaction", null);
      }
    }, 250);
  }

  loadFilters() {
    if(this.external){
      this.updateFilters();
      return;
    }
    this.freeSearch = this.getFilter("searchTransaction");
    this.currentPage = (this.getFilter("pageTransaction") ? this.getFilter("pageTransaction") : 1);
    this.posFilter = this.getFilter("posTransaction");
    this.posUserFilter = this.getFilter("posUserTransaction");
    this.eventFilter = this.getFilter("eventTransaction");
    this.guestFilter = this.getFilter("guestTransaction");
    console.log(this.guestFilter);
    if(this.getFilter("dateFromTransaction") && this.getFilter("dateToTransaction")){
      this.startInput = moment(this.getFilter("dateFromTransaction")).toDate();
      this.endInput = moment(this.getFilter("dateToTransaction")).toDate();
    }
    this.updateFilters();
    //this.cdr.detectChanges();
  }

  @Input() startInput: Date;
  @Input() endInput: Date;

  setDateRange(event) {
    let dates: Date[] = event;
    this.startInput = moment(dates[0]).toDate();
    this.endInput = moment(dates[1]).toDate();
    this.updateFilters();
    this.loadTransactions();
  }

  posFilter: number;
  posUserFilter: number;
  eventFilter: number;
  guestFilter: number;
  incomingPaginatedSelectValue(fieldName, value) {
    let _this = this;
    switch (fieldName) {
      case 'pos':
        _this.posFilter = value;
        break;
      case 'pos-user':
        _this.posUserFilter = value;
        break;
      case 'event':
        _this.eventFilter = value;
        break;
      case 'guest':
        _this.guestFilter = value;
        break;
    }
    this.updateFilters();
  }

  filterLoadTimeout: any
  updateFilters(skip?) {
    let _this = this;
    _this.searchParams = { per_page: environment.per_page, page: 1 };
    if (!skip) {
      _this.searchParams.page = _this.currentPage;
    }
    else {
      _this.currentPage = 1;
      _this.setFilter("pageTransaction", 1);
    }

    if (this.startInput && this.endInput) {
      this.searchParams['date_from'] = moment(this.startInput).format();
      this.searchParams['date_to'] = moment(this.endInput).format();
    }

    if (this.external) {
      return;
    }

    if (this.freeSearch) {
      this.searchParams['q'] = this.freeSearch;
    }
    if (this.posFilter) {
      this.searchParams['pos'] = this.posFilter;
    }

    if (this.posUserFilter) {
      this.searchParams['pos_user'] = this.posUserFilter;
    }

    if (this.eventFilter) {
      this.searchParams['event'] = this.eventFilter;
    }

    if (this.guestFilter) {
      this.searchParams['guest'] = this.guestFilter;
    }

    if (this.filterLoadTimeout) {
      clearTimeout(this.filterLoadTimeout);
    }
    this.filterLoadTimeout = setTimeout(() => {
      this.loadTransactions();
    }, 350);
    this.saveFilters();
  }


  //List Dot Menu Bulk Selection

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  openBulk() {
    this.mdSlideUp.show()
  }

  mainToggle: boolean = false;
  activeActionTitle: string;
  menuItems: any[] = [
    { action: 'export-selected', message: 'Export Selected', class: '', icon: 'fal fa-file-export', permissions: ['view_transaction'] },
    { action: 'export-all', message: 'Export All', class: '', icon: 'fal fa-file-export', permissions: ['view_transaction'] },
  ];

  handleButtonPress(event) {
    switch (event) {
      case "export-selected":
        this.activeActionTitle = "Export Selected";
        this.openBulk();
        break;
      case "export-all":
        this.activeActionTitle = "Export All";
        this.openBulk();
        break;
      default:
        console.log(event);
        break;
    }
  }


  activeRows: Map<number, any> = new Map<number, any>();
  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if (row['bulk_checked']) {
      _this.activeRows.set(row['id'], row);
    }
    else {
      _this.activeRows.delete(row['id']);
    }
    this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }

  updateMenuItems() {
    for (let item of this.menuItems) {
      if (this.activeRows.size == 0) {
        if (item.action.includes('export-selected')) {
          item.Disabled = true;
        }
      }
      else {
        if (item.action.includes('export-selected')) {
          item.Disabled = false;
        }
      }
    }
    return this.menuItems;
  }

  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if (this.mainToggle == true) {
        _this.activeRows.set(item.id, item);
      }
      else {
        _this.activeRows.delete(item.id);
      }
    }
    this.updateMenuItems();
  }

  submitToggle() {
    this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), 'Transaction');
    this.activeRows = new Map<number, any>();
  }

}