import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'btn-loading',
  templateUrl: './btn-loading.component.html',
  styleUrls: ['./btn-loading.component.scss']
})
export class ButtonLoadingComponent implements OnInit {

  @Input() classes:string = "";
  @Input() classesProgress:string = "small-progress save";
  @Input() title:string = "Save";
  @Input() isLoading:boolean = false;
  @Input() icon:string = "fal fa-save";
  @Input() permission:any = [];
  @Output() submitEvent:EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private permissionService : NgxPermissionsService
  ) { }

  ngOnInit() {
    this.classesProgress += ' '+this.classes;
    if(this.classes.length > 0){
      this.classes += " btn float-right";
    }
    else{
      this.classes += " btn btn-save float-right";
    }
  }
  
  onButtonPress(){
    if(this.isLoading){
      return false;
    }
    this.submitEvent.emit('submit');
  }

}