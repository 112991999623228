import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'layout-blank-simplywhite',
  templateUrl: './blank-simplywhite.component.html',
  styleUrls: ['./blank-simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlankSimplywhiteComponent implements OnInit {
  constructor(
    private authService : AuthService
  ) {}

  ngOnInit() {}

  getLogo() {
    return this.authService.getLogo();
  }

  isAuthenticated() {
    if (this.authService.initialTimeout || !this.authService.didFirstCheck) {
      return false;
    }
    return this.authService.authenticated;
  }

}
