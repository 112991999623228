export { RootLayout } from './root/root.component';
export { BlankComponent } from './blank/blank.component';
//export { CondensedComponent } from './condensed/condensed.component';
//export { CorporateLayout } from './corporate/corporate.component';
export { SimplyWhiteLayout } from './simplywhite/simplywhite.component';
//export { ExecutiveLayout } from './executive/executive.component';
//export { CasualLayout } from './casual/casual.component';
//export { BlankCasualComponent } from './blank-casual/blank-casual.component';
//export { BlankCorporateComponent } from './blank-corporate/blank-corporate.component';
export { BlankSimplywhiteComponent } from './blank-simplywhite/blank-simplywhite.component';
//Remove in production
//This is a demo a layout
//export { DemoComponent } from './demo/demo.component';
