import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor() { }


  getErrors(err) {
    let tmp: string[] = [];
    if(err){
      let errors : string[] = Object.values(err);
      for (let errorList of errors) {
        for (let error of errorList) {
          tmp.push(error);
        }
      }
    }
    return tmp;
  }

  formatErrorMessages(err){
    for(let error in err){
      for(let arrayError in err[error]){
        console.log(arrayError);
        if(err[error][arrayError].includes("greater than 0")){
          switch(error){
            case 'purchase_price':
              err[error][arrayError] = "The Cost Price must be greater than 0.";
              break;
              case 'sale_price':
                err[error][arrayError] = "The Selling Price must be greater than 0.";
              break;
          }
        }
      }
    }
    return err;
  }

}
