import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { isArray } from 'util';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { TokenModel } from '../../../shared/models/token.model';
import { TokenService } from '../../../shared/services/token.service';
import { ClientModel } from '../../../shared/models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { ClientService } from '../../../shared/services/client.service';
import { EventModel } from '../../../shared/models/event.model';
import * as moment from 'moment';
import { EventService } from '../../../shared/services/event.service';
import { POSService } from '../../../shared/services/pos.service';
import { DotMenuComponent } from '../../components/dot-menu/dot-menu.component';

@Component({
  selector: 'app-token-detail',
  templateUrl: './token-detail.component.html',
  styleUrls: ['./token-detail.component.scss']
})
export class TokenDetailComponent implements OnInit {

  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  incomingClientEvent: EventEmitter<any> = new EventEmitter<any>();
  token: TokenModel;
  tokenForm: FormGroup;
  roles: any[];
  @ViewChild('deleteToken', { static: true }) deleteToken: ModalDirective;
  @ViewChild('chargeToken', { static: true }) chargeToken: ModalDirective;
  @ViewChild('blockToken', { static: true }) blockToken: ModalDirective;
  @ViewChild('unblockToken', { static: true }) unblockToken: ModalDirective;
  @ViewChild('depositToken', { static: true }) depositToken: ModalDirective;
  @ViewChild('transferToken', { static: true }) transferToken: ModalDirective;
  @ViewChild('eventLinkToken', { static: true }) eventLinkToken: ModalDirective;
  @ViewChild('createGuestModal', { static: true }) createGuestModal: ModalDirective;
  errors: String[] = [];
  tokenValue: number = 0;
  userPin: string = '';
  ColumnMode = ColumnMode;
  constructor(
    private cdr: ChangeDetectorRef,
    private tokenService: TokenService,
    private fb: FormBuilder,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private clientService : ClientService,
    private posService : POSService,
    private _location: Location,
    private eventService : EventService
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.loadTypes();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadToken(res['params']['id']);
      }
    })
  }

  cardMenuItems: any[] = [
    { action: 'delete', message: 'Unlink', class: '', icon: 'fal fa-unlink', permissions: [''] }
  ];

  activeDotMenus: Map<number, DotMenuComponent> = new Map<number, DotMenuComponent>();
  activeRow:number;
  handleDotMenu(event, row, dotMenu) {
    this.activeDotMenus.set(row['id'], dotMenu);
    this.activeDotMenus.forEach((value, key) => {
      if (key == row['id']) {
        for (let mI of value.menuItems) {
          mI['hidden'] = false;
          // if (mI.action == 'active' && row['active_card']) {
          //   mI['hidden'] = true;
          // }
        }
      }
      if (value && value.state && key != row['id']) {
        value.state = false;
      }
    });
  }

  @ViewChild('tokenUnlink', { static: true }) unlinkToken: ModalDirective;
  cardButtonPress(event, row) {
    console.log(row);
    console.log(event);
    switch (event) {
      case "delete":
        this.unlinkToken.show();
        this.activeRow = row['event'];
        console.log(this.activeRow);
        break;
      default:
        console.log(event);
        break;
    }
  }

  OnUnlink(){
    let _this = this;
    this.eventService.deleteTokenLink(this.activeRow['id'],this.token.id).subscribe(res => {
      console.log(res);
      //_this.loadTokenEvents();
      _this.unlinkToken.hide();
      _this.activeRow = null;
      this.messageService.remove();
      this.messageService.success("Event Unlinked.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    },
    err => {
      this.messageService.remove();
      this.messageService.error("Unlink Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      console.log(err);
    });
  }

  types: any = [];
  loadTypes() {
    let _this = this;
    this.tokenService.getTypes().subscribe(res => {
      _this.types = res['data'];
      console.log(res);
    },
      err => {
        console.log(err);
      })
  }

  menuItems = [];

  setMenuItems() {
    if (this.token.is_blocked) {
      this.menuItems = [
        { action: 'unblock', message: 'Unblock', class: '', icon: 'fal fa-unlock', permissions: ['unblock_token'] },//unblock_token
        { action: 'delete', message: 'Delete', class: '', icon: 'fal fa-trash', permissions: ['delete_token'] }
      ];
    }
    else {
      this.menuItems = [
        { action: 'deposit', message: 'Top Up', class: '', icon: 'fal fa-wallet', permissions: ['topup_token'] },
        { action: 'charge', message: 'Charge', class: '', icon: 'fal fa-receipt', permissions: ['charge_token'] },
        { action: 'block', message: 'Block', class: '', icon: 'fal fa-ban', permissions: ['block_token'] },//block_token
        { action: 'transfer', message: 'Replace', class: '', icon: 'fal fa-exchange', permissions: ['transfer_token'] },
        { action: 'link', message: 'Assign Access', class: '', icon: 'fal fa-calendar-week', permissions: ['link_token_event'] },//link_token_event
      ];
      if (!this.token.activated_at) {
        this.menuItems.push({ action: 'activate', message: 'Activate', class: '', icon: 'fal fa-power-off', permissions: ['activate_token'] });
      }
      this.menuItems.push({ action: 'delete', message: 'Delete', class: '', icon: 'fal fa-trash', permissions: ['delete_token'] });
    }
  }

  
  @ViewChild('activateToken', { static: true }) activateModal: ModalDirective;
  activate(){
    if(!this.expiry_date){
      this.messageService.success("Expiry Date is must be filled.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      return;
    }
    let _this = this;
    this.tokenService.activate(this.token.id,moment(_this.expiry_date).format()).subscribe(res => {
      _this.tokenForm.get('activated_at').setValue(new Date());
      _this.activateModal.hide();
    },
    err => {
      console.log(err);
    })
  }

  handleButtonPress(event) {
    switch (event) {
      case "delete":
        this.deleteToken.show();
        break;

      case "charge":
        this.tokenValue = 0;
        this.chargeToken.show();
        break;

      case "deposit":
        this.tokenValue = 0;
        this.depositToken.show();
        break;

      case "block":
        this.blockToken.show();
        break;

      case "unblock":
        this.unblockToken.show();
        break;

      case "transfer":
        this.transferToken.show();
        break;

      case "link":
        this.eventLinkToken.show();
        break;

      case "activate":
        this.activateModal.show();
        break;

      default:
        console.log(event);
        break;
    }
  }

  block() {
    let _this = this;
    this.isLoading = true;
    this.tokenService.block(this.token.id).subscribe(res => {
      _this.token = res['data'];
      _this.setMenuItems();
      _this.blockToken.hide();
      if (_this.token.guest) {
        _this.incomingClientEvent.emit(_this.token.guest);
      }
      _this.createForm(res['data']);
      _this.isLoading = false;
      this.messageService.success("Token has been Blocked.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    },
      err => {
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
      });
  }

  unblock() {
    let _this = this;
    this.isLoading = true;
    this.tokenService.unblock(this.token.id).subscribe(res => {
      _this.token = res['data'];
      _this.setMenuItems();
      _this.unblockToken.hide();
      if (_this.token.guest) {
        _this.incomingClientEvent.emit(_this.token.guest);
      }
      this.messageService.success("Token has been Unblocked.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.createForm(res['data']);
      _this.isLoading = false;
    },
      err => {
        _this.isLoading = false;
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
      });
  }

  transferTokenId;
  events;
  posId: number;
  incomingPaginatedSelectValue(fieldName, value) {
    let _this = this;
    switch (fieldName) {
      case 'guest':
        _this.tokenForm.get('guest').setValue(value);
        break;
      case 'token':
        _this.transferTokenId = value;
        break;
      case 'event':
        _this.events = value;
        break;
      case 'pos':
        _this.posId = value;
        break;
    }
  }

  onTokenTransfer() {
    let _this = this;
    this.isLoading = true;
    this.tokenService.transfer(this.token.uuid, this.transferTokenId).subscribe(res => {
      this.messageService.success("Token Transferred.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      this.transferToken.hide();
      _this.transferTokenId = null;
      _this.isLoading = false;
    },
      err => {
        console.log(err);
        _this.isLoading = false;
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      })
  }

  eventReload: EventEmitter<any> = new EventEmitter<any>();
  onLinkEvents() {
    let _this = this;
    this.isLoading = true;
    this.tokenService.linkEvents(this.token.id, this.events).subscribe(res => {
      this.messageService.success("Token Linked.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.eventReload.emit({type:'reload',value:true});
      this.eventLinkToken.hide();
      _this.events = null;
      _this.isLoading = false;
    },
      err => {
        console.log(err);
        _this.isLoading = false;
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      })
  }

  @ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
  loadSavedData() {
    if (localStorage.getItem("token-create")) {
      this.createForm(JSON.parse(localStorage.getItem("token-create")));
      // if (this.tokenForm.get('phone').value) {
      //   this.tokenForm.get('phone').setValue(this.tokenForm.get('phone').value['nationalNumber'].replace(" ", ""));
      // }
    }
    this.tokenForm.markAllAsTouched();
    this.loadDataModal.hide();
  }

  deleteData() {
    localStorage.removeItem("token-create");
    this.loadDataModal.hide();
  }

  sendBack() {
    this._location.back();
  }

  page: Page[] = [new Page(), new Page(), new Page()];
  currentPage: number[] = [1, 1, 1];
  searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

  markTouched() {
    this.tokenForm.markAsTouched();
  }

  createForm(input?) {
    let _this = this;
    if (input) {
      input['guest'] = input['guest'] ? input['guest']['id'] : null;
    }
    input = input || {};
    this.tokenForm = this.fb.group({
      type: [input.type, Validators.required],
      guest: [input.guest],
      uuid: [input.uuid, Validators.required],
      activated_at: [input.activated_at],
      remaining_balance: [input.remaining_balance, Validators.required],
      total_topped_up:[input.total_topped_up],
      total_spent:[input.total_spent],
      expiry_date: [input.expiry_date],
    });
    setTimeout(() => {
      if(_this.token){
        _this.tokenForm.get('uuid').disable();
      }
    }, 250);
    this.tokenForm.valueChanges.subscribe(changes => {
      if(_this.token){
        if(!_this.tokenForm.get('uuid').disabled){
          _this.tokenForm.get('uuid').disable();
        }
      }
    })
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  openGuestModal() {
    this.createGuestModal.show();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  addClient(client: ClientModel) {
    this.createGuestModal.hide();
    this.incomingClientEvent.emit({ type: 'add_new_client', client: client });
    this.tokenForm.get('guest').setValue(client.id);
  }

  uploadFile(fileInput) {
    console.log(fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        console.log(e.target.result);
        //$('#preview').attr('src', e.target.result);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  isTouched(controlName) {
    if (this.tokenForm.get(controlName).touched && this.tokenForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  isTouchedControl(control) {
    if ($(control).hasClass('ng-touched') && $(control).hasClass('ng-invalid')) {
      return true;
    }
    else {
      return false;
    }
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  loadToken(id) {
    let _this = this;
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    this.tokenService.get(id).subscribe(res => {
      res['data']['type'] = res['data']['type']['id'];
      _this.token = res['data'];
      _this.loadSpendData();
      console.log(_this.token);
      _this.setMenuItems();
      if (_this.token.guest) {
        _this.incomingClientEvent.emit(_this.token.guest);
      }
      if (res['data']['guest']) {
        res['data']['guest'] = res['data']['guest']['id'];
      }
      _this.createForm(res['data']);
      _this.loading.isLoading = false;
      _this.cdr.detectChanges();
    },
      err => {
        _this.loading.isLoading = false;
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
      });
  }


  getType(id){
    for(let type of this.types){
      if(type.id == id){
        return type.title;
      }
    }
  }

  loadSpendData(){
    let _this = this;
    this.tokenService.getTokenSpendingData(_this.token.id).subscribe(res => {
      _this.tokenForm.get('total_topped_up').setValue(res['data']['topups']);
      _this.tokenForm.get('total_spent').setValue(res['data']['charges']);
      console.log(res);
    },
    err => {
      console.log(err);
    })
  }

  delete() {
    let _this = this;
    this.isLoading = true;
    this.tokenService.delete(this.token.id).subscribe(res => {
      this.router.navigate(['/token']);
      this.messageService.remove();
      this.messageService.success("token Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.isLoading = false;
    },
      err => {
        this.messageService.remove();
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
        _this.isLoading = false;
      });
  }

  expiry_date:Date;

  @Input() isLoading: boolean = false;
  save() {
    this.tokenForm.updateValueAndValidity();
    if (this.tokenForm.invalid) {
      this.tokenForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }

    if (this.isLoading) {
      return;
    }

    localStorage.removeItem("token-create");
    let _this = this;

    _this.isLoading = true;

    let rawValue = this.tokenForm.getRawValue();
    this.tokenForm.markAsUntouched();
    this.tokenForm.updateValueAndValidity();

    if (rawValue['activated_at']) {
      rawValue['activated_at'] = moment(rawValue['activated_at']).format();
    }
    if (rawValue['expiry_date']) {
      rawValue['expiry_date'] = moment(rawValue['expiry_date']).format();
    }

    if (this.token) {
      this.tokenService.edit(this.token.id, rawValue).subscribe(res => {
        this.messageService.remove();
        this.messageService.success("Token Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        // _this.createForm(res['data']);
        // console.log(_this.tokenForm);
        // _this.loadToken();
        _this.isLoading = false;
        _this.token = res['data'];
        _this.setMenuItems();
        if (_this.token.guest) {
          _this.incomingClientEvent.emit(_this.token.guest);
        }
        if (res['data']['guest']) {
          res['data']['guest'] = res['data']['guest']['id'];
        }
        res['data']['type'] = res['data']['type']['id'];
        _this.createForm(res['data']);
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          this.messageService.remove();
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          console.log(err);
          _this.isLoading = false;
        });
    }
    else {
      this.tokenService.create(rawValue).subscribe(res => {
        if (_this.external == true) {
          _this.saveEvent.emit(res['data']);
        }
        else {
          this.router.navigate(['/token/' + res['data']['id']]);
        }
        this.messageService.remove();
        this.messageService.success("Token User Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          console.log(err);
          _this.isLoading = false;
        });
    }
  }

  transactionType: string = '';

  charge() {
    let _this = this;
    let event: EventModel;
    this.isLoading = true;
    let params = { amount: this.tokenValue, event: event, pos: this.posId, pin: this.userPin };
    this.tokenService.charge(this.token.id, params).subscribe(res => {
      _this.isLoading = false;
      this.messageService.success("Charged Amount Successfully!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.tokenForm.get('remaining_balance').setValue(res['data']['remaining_balance']);
      _this.tokenValue = 0;
      _this.chargeToken.hide();
    },
      err => {
        console.log(err);
        _this.isLoading = false;
        this.messageService.error("Charge Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      })
  }

  deposit() {
    let _this = this;
    let event: EventModel;
    this.isLoading = true;
    let params = { amount: this.tokenValue, event: event, type: this.transactionType, pos: this.posId , pin: this.userPin};
    this.tokenService.topup(this.token.id, params).subscribe(res => {
      _this.isLoading = false;
      this.messageService.success("Topped Up Successfully!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.tokenForm.get('remaining_balance').setValue(res['data']['remaining_balance']);
      _this.tokenValue = 0;
      _this.depositToken.hide();
    },
      err => {
        console.log(err);
        _this.isLoading = false;
        this.messageService.error("Top Up Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.tokenValue = 0;
      })
  }


}
