import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate() {
    let _this = this;
    if (this.auth.authenticated) {
      return true;
    } else {
      let check = setInterval(() => {
       if(_this.auth.didFirstCheck){
        if(this.auth.authenticated){
          clearInterval(check);
          return true;
        }
        else{
          clearInterval(check);
          //this.router.navigateByUrl('/session/login');
        }
       }
      }, 500);
    }
  }
}