import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { isArray } from 'util';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { EventModel } from '../../../shared/models/event.model';
import { EventService } from '../../../shared/services/event.service';
import * as moment from 'moment';
import { pgRangePickerComponent } from '../../../@pages/components/datepicker/rangepicker.component';
import { TokenService } from '../../../shared/services/token.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { DotMenuComponent } from '../../components/dot-menu/dot-menu.component';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  @Input() external:boolean = false;
  @Output() saveEvent:EventEmitter<any> = new EventEmitter<any>();
  fromDate:Date;
  toDate:Date;
  event: EventModel;
  eventForm: FormGroup;
  roles: any[];
  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  errors: String[] = [];
  ColumnMode = ColumnMode;
  selectedValues:number[] = [];
  constructor(
    private cdr: ChangeDetectorRef,
    private eventService: EventService,
    private fb: FormBuilder,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private _location: Location,
    private tokenService : TokenService
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.loadLocalities();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadEvent(res['params']['id']);
      }
    })
  }

  cardMenuItems: any[] = [
    { action: 'delete', message: 'Unlink', class: '', icon: 'fal fa-unlink', permissions: [''] }
  ];

  activeDotMenus: Map<number, DotMenuComponent> = new Map<number, DotMenuComponent>();
  activeRow:number;
  handleDotMenu(event, row, dotMenu) {
    this.activeDotMenus.set(row['id'], dotMenu);
    this.activeDotMenus.forEach((value, key) => {
      if (key == row['id']) {
        for (let mI of value.menuItems) {
          mI['hidden'] = false;
          // if (mI.action == 'active' && row['active_card']) {
          //   mI['hidden'] = true;
          // }
        }
      }
      if (value && value.state && key != row['id']) {
        value.state = false;
      }
    });
  }

  @ViewChild('tokenUnlink', { static: true }) unlinkToken: ModalDirective;
  cardButtonPress(event, row) {
    console.log(row);
    console.log(event);
    switch (event) {
      case "delete":
        this.unlinkToken.show();
        this.activeRow = row['token'];
        break;
      default:
        console.log(event);
        break;
    }
  }

  OnUnlink(){
    let _this = this;
    this.eventService.deleteTokenLink(this.event.id,this.activeRow['id']).subscribe(res => {
      console.log(res);
      _this.loadEventTokens();
      _this.unlinkToken.hide();
      _this.activeRow = null;
      this.messageService.remove();
      this.messageService.success("Token Unlinked.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    },
    err => {
      this.messageService.remove();
      this.messageService.error("Unlink Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      console.log(err);
    });
  }

  rows = [];
  eventTokens:any[];
  loadEventTokens() {
    let _this = this;
    _this.eventTokens = [];
    _this.selectedValues = [];
    this.eventService.getLinkedTokens(this.event.id).subscribe(res => {
      _this.eventTokens = res['data'];
      _this.rows = [];
      for (let Event of _this.eventTokens) {
        _this.rows.push(
          {
            id: Event.id,
            token: Event.token,
            guest: Event.guest,
            created_at: Event.created_at
          }
        );
        _this.selectedValues.push(Event.token.id);
      }
      console.log(_this.page);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  setDateRange(event) {
    let dates: Date[] = event;
    this.fromDate = dates[0];
    this.toDate = dates[1];
    if(this.eventForm){
      this.eventForm.get("event_start").setValue(moment(this.fromDate).format());
      this.eventForm.get("event_finish").setValue(moment(this.toDate).format());
    }
    console.log(this.eventForm.getRawValue());
    //this.loadByDate();
}

  menuItems: any[] = [
    { action: 'link', message: 'Assign Access', class: '', icon: 'fal fa-calendar-week', permissions: ['link_token_event'] },//link_token_event
    { action: 'delete', message: 'Delete Event', class: '', icon: 'fal fa-trash', permissions: ['delete_event'] },
  ];

  handleButtonPress(event) {
    switch (event) {
      case "delete":
        this.mdSlideUp.show();
        break;
        case "link":
          this.eventLinkToken.show();
          break;
      default:
        console.log(event);
        break;
    }
  }

  loadLocalities() {
    let _this = this;
    // this.localityService.getAll().subscribe(res => {
    //   _this.localities = res['data'];
    // },
    // err => {
    //   console.log(err);
    // })
  }

  @ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
  loadSavedData() {
    if (localStorage.getItem("event-create")) {
      this.createForm(JSON.parse(localStorage.getItem("event-create")));
      // if (this.eventForm.get('phone').value) {
      //   this.eventForm.get('phone').setValue(this.eventForm.get('phone').value['nationalNumber'].replace(" ", ""));
      // }
    }
    this.eventForm.markAllAsTouched();
    this.loadDataModal.hide();
  }

  deleteData() {
    localStorage.removeItem("event-create");
    this.loadDataModal.hide();
  }

  sendBack() {
    this._location.back();
  }

  page: Page[] = [new Page(), new Page(), new Page()];
  currentPage: number[] = [1, 1, 1];
  searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

  markTouched() {
    this.eventForm.markAsTouched();
  }

  createForm(input?) {
    input = input || {};
    this.eventForm = this.fb.group({
      title: [input.title, Validators.required],
      description: [input.description, Validators.required],
      event_start: [input.event_start],
      event_finish: [input.event_finish],
      is_available_to_premium: [input.is_available_to_premium]
    });
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  uploadFile(fileInput) {
    console.log(fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        console.log(e.target.result);
        //$('#preview').attr('src', e.target.result);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  isTouched(controlName) {
    if (this.eventForm.get(controlName).touched && this.eventForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  loadEvent(id) {
    let _this = this;
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    this.eventService.get(id).subscribe(res => {
      _this.event = res['data'];
      _this.createForm(_this.event);
      _this.fromDate = moment(res['data']['event_start']).toDate();
      _this.toDate = moment(res['data']['event_finish']).toDate();
      _this.loading.isLoading = false;
      _this.loadEventTokens();
    },
    err => {
      _this.loading.isLoading = false;
      console.log(err);
    });
  }
  _disabledStartDate = (startValue) => {
    if (!startValue) {
      return moment().isBefore(new Date());
    }
    return moment(startValue).isBefore(new Date());
  };

  _disabledEndDate = (endValue) => {
    return moment(endValue).isBefore(moment(this.fromDate));
  };

  isChecked(){
    if(!this.eventForm || !this.eventForm.get("is_available_to_premium").value){
      return false;
    }
    return this.eventForm.get("is_available_to_premium").value;
  }
  
  @ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
  @ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
  forceRefresh(dateEvent) {
    let _this = this;
    // if (dateEvent.date) {
    //   if (moment(new Date(dateEvent.date)).isBefore(_this.fromDate)) {
    //     dateEvent.date = _this.fromDate;
    //   }
    //   if (moment(new Date(dateEvent.date)).isAfter(_this.toDate)) {
    //     dateEvent.date = _this.toDate;
    //   }
    // }
    if (dateEvent.isStart == false) {
      _this.toDate = new Date(dateEvent.date)
    }
    else {
      _this.fromDate = new Date(dateEvent.date)
    }
    if (!this.fromDate && !this.toDate) {
      return;
    }

    if (moment(this.fromDate).isAfter(moment(this.toDate))) {
      this.toDate = moment(this.fromDate).add(1, 'h').toDate();
    }

    if (this.fromPicker) {
      if (this.fromPicker.timePickerInner) {
        this.fromPicker.timePickerInner.updateTime();
      }
    }
    if (this.toPicker) {
      if (this.toPicker.timePickerInner) {
        this.toPicker.timePickerInner.updateTime();
      }
    }
    _this.cdr.detectChanges();
  }


  tokenList:number;
  incomingPaginatedSelectValue(fieldName, value) {
    let _this = this;
    switch (fieldName) {
      case 'token':
        _this.tokenList = value;
        break;
    }
  }

  @ViewChild('eventLinkToken', { static: true }) eventLinkToken: ModalDirective;
  onLinkEvent() {
    let _this = this;
    this.isLoading = true;
    this.eventService.linkTokens(this.event.id, this.tokenList).subscribe(res => {
      this.messageService.success("Tokens Linked.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      this.eventLinkToken.hide();
      _this.tokenList = null;
      _this.isLoading = false;
      _this.paginatedTokenSelect.row = [];
      _this.paginatedTokenSelect.form.reset();
      _this.loadEventTokens();
    },
      err => {
        console.log(err);
        _this.isLoading = false;
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      })
  }

  delete() {
    let _this = this;
    this.isLoading = true;
    this.eventService.delete(this.event.id).subscribe(res => {
      this.router.navigate(['/event']);
      this.messageService.remove();
      this.messageService.success("event Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.isLoading = false;
    },
      err => {
        this.messageService.remove();
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
        _this.isLoading = false;
      });
  }

  showSlideUp() {
    this.mdSlideUp.show();
  }

  @Input() isLoading:boolean = false;
  @ViewChild('paginatedToken',{static:false}) paginatedTokenSelect: PaginatedSelectComponent;
  save() {
    this.eventForm.updateValueAndValidity();
    if (this.eventForm.invalid) {
      this.eventForm.markAllAsTouched();
      this.isLoading = false;
      console.log("Invalid Form: ",this.eventForm);
      return;
    }

    if(!this.fromDate || !this.toDate){
      //return;
    }

    if(this.isLoading){
      return;
    }

    localStorage.removeItem("event-create");
    let _this = this;

    _this.isLoading = true;

    let rawValue = this.eventForm.getRawValue();

    rawValue["event_start"] = moment(this.fromDate).format();
    rawValue["event_finish"] = moment(this.toDate).format();

    this.eventForm.markAsUntouched();
    this.eventForm.updateValueAndValidity();
    if (this.event) {
      this.eventService.edit(this.event.id, rawValue).subscribe(res => {
        this.messageService.remove();
        this.messageService.success("Event Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(res['data']);
        _this.createForm(res['data']);
        console.log(_this.eventForm);
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          this.messageService.remove();
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          console.log(err);
          _this.isLoading = false;
        });
    }
    else {
      this.eventService.create(rawValue).subscribe(res => {
        if(_this.external == true){
          _this.saveEvent.emit(res['data']);
        }
        else{
          this.router.navigate(['/event/' + res['data']['id']]);
        }
        this.messageService.remove();
        this.messageService.success("Event Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          console.log(err);
          _this.isLoading = false;
        });
    }
  }


}
