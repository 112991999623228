import { FormBuilder, FormGroup } from "@angular/forms";
import { UserModel } from "./user.model";

export class ReportModel {
    id?: number;
    total_sales:string;
    total_tips:string;
    topups_by_users:any[];
	topups_by_type:any[];
	sales_by_users:any[];
	tips_by_users:any[];
	transactions:any[];
  
    constructor(input?) {
      input = input || {};
      this.id = input.id || '';
      this.total_sales = input.total_sales || '';
      this.total_tips = input.total_tips || '';
      this.topups_by_users = input.topups_by_users || [];
	  this.topups_by_type = input.topups_by_type || [];
	  this.sales_by_users = input.sales_by_users || [];
	  this.tips_by_users = input.tips_by_users || [];
	  this.transactions = input.transactions || [];
    }

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();

		
		return fb.group({
			total: [input.total],
			total_sales: [input.total_sales],
			total_tips: [input.total_tips],
			topups_by_users: [{}],
			topups_by_type: [{}],
			sales_by_users: [{}],
			tips_by_users: [{}],
			transactions: [{}],
		  })
		}
  }
  