import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { isArray } from 'util';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { VoucherModel } from '../../../shared/models/voucher.model';
import { VoucherService } from '../../../shared/services/voucher.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.scss']
})
export class VoucherDetailComponent implements OnInit {

  euroPrefix = createNumberMask({
    prefix: '€ ',
    suffix: '',
    allowDecimal: true
  });
  @Input() external:boolean = false;
  @Output() saveEvent:EventEmitter<any> = new EventEmitter<any>();
  voucher: VoucherModel;
  voucherForm: FormGroup;
  roles: any[];
  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  errors: String[] = [];
  ColumnMode = ColumnMode;
  constructor(
    private cdr: ChangeDetectorRef,
    private voucherService: VoucherService,
    private fb: FormBuilder,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private _location: Location
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadVoucher(res['params']['id']);
      }
    })
  }

  menuItems: any[] = [
    { action: 'delete', message: 'Delete Voucher', class: '', icon: 'fal fa-trash', permissions: ['delete_voucher'] }
  ];

  handleButtonPress(event) {
    switch (event) {
      case "delete":
        this.mdSlideUp.show();
        break;
      default:
        console.log(event);
        break;
    }
  }

  @ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
  loadSavedData() {
    if (localStorage.getItem("voucher-create")) {
      this.createForm(JSON.parse(localStorage.getItem("voucher-create")));
    }
    this.voucherForm.markAllAsTouched();
    this.loadDataModal.hide();
  }

  deleteData() {
    localStorage.removeItem("voucher-create");
    this.loadDataModal.hide();
  }

  sendBack() {
    this._location.back();
  }

  page: Page[] = [new Page(), new Page(), new Page()];
  currentPage: number[] = [1, 1, 1];
  searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

  markTouched() {
    this.voucherForm.markAsTouched();
  }

  createForm(input?) {
    input = input || {};
    this.voucherForm = this.fb.group({
      first_name: [input.first_name, Validators.required],
      last_name: [input.last_name, Validators.required],
      email: [input.email, [Validators.email,Validators.required]],
      payment_amount: [input.payment_amount, Validators.required],
      // payment_completed_at: [input.payment_completed_at],
      // payment_currency: [input.payment_currency],
      // payment_status: [input.payment_status],
      phone_number: [input.phone_number, Validators.required],
      // purchased_credit: [input.purchased_credit],
      // redeemed_at: [input.redeemed_at],
      reservation_number: [input.reservation_number, Validators.required],
      // topup_credit: [input.topup_credit],
      // voucher_number: [input.voucher_number],
    });
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  uploadFile(fileInput) {
    console.log(fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        console.log(e.target.result);
        //$('#preview').attr('src', e.target.result);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  isTouched(controlName) {
    if (this.voucherForm.get(controlName).touched && this.voucherForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  loadVoucher(id) {
    let _this = this;
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    this.voucherService.get(id).subscribe(res => {
      _this.voucher = res['data'];
      _this.createForm(_this.voucher);
      _this.loading.isLoading = false;
    },
    err => {
      _this.loading.isLoading = false;
      console.log(err);
    });
  }

  delete() {
    let _this = this;
    this.isLoading = true;
    this.voucherService.delete(this.voucher.id).subscribe(res => {
      this.router.navigate(['/voucher']);
      this.messageService.remove();
      this.messageService.success("Voucher Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.isLoading = false;
    },
      err => {
        this.messageService.remove();
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
        _this.isLoading = false;
      });
  }

  showSlideUp() {
    this.mdSlideUp.show();
  }

  @Input() isLoading:boolean = false;
  save() {
    this.voucherForm.updateValueAndValidity();
    if (this.voucherForm.invalid) {
      this.voucherForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }

    if(this.isLoading){
      return;
    }

    localStorage.removeItem("voucher-create");
    let _this = this;

    _this.isLoading = true;

    let rawValue = this.voucherForm.getRawValue();
    this.voucherForm.markAsUntouched();
    this.voucherForm.updateValueAndValidity();
    rawValue['payment_amount'] = rawValue['payment_amount'].toString().replace(/[^0-9\.]+/g, "");
    
    if (this.voucher) {
      this.voucherService.edit(this.voucher.id, rawValue).subscribe(res => {
        this.messageService.remove();
        this.messageService.success("Voucher Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(res['data']);
        _this.createForm(res['data']);
        console.log(_this.voucherForm);
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          this.messageService.remove();
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          console.log(err);
          _this.isLoading = false;
        });
    }
    else {
      this.voucherService.create(rawValue).subscribe(res => {
        if(_this.external == true){
          _this.saveEvent.emit(res['data']);
        }
        else{
          this.router.navigate(['/voucher/' + res['data']['id']]);
        }
        this.messageService.remove();
        this.messageService.success("Voucher Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          console.log(err);
          _this.isLoading = false;
        });
    }
  }


}
