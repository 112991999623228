import { Component, OnInit, ViewChild } from '@angular/core';
import { EChartOption } from 'echarts';
import * as moment from 'moment';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { isDate } from 'util';
import { AuthService } from '../../../shared/services/auth.service';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { Subscription } from 'rxjs';
import { ClientModel } from '../../../shared/models/client.model';
import { ClientService } from '../../../shared/services/client.service';
import { ReportService } from '../../../shared/services/report.service';
import { Utils } from '../../../shared/services/utils.service';

@Component({
    selector: 'app-report-token-statistics',
    templateUrl: './report-token.component.html',
    styleUrls: ['./report-token.component.scss']
})
export class TokenReportComponent implements OnInit {

    transactionChart: EChartOption;
    posChart: EChartOption;
    posUserChart: EChartOption;
    statModel: any;
    labelOption = {
        show: true,
        rotate: 90,
        formatter: '{a.char(0)}',
        fontSize: 16
    };
    periodsMap: Map<number, any> = new Map<number, any>();
    periods: any[] = [{ id: 1, title: "Last 24 Hours" }, { id: 2, title: "Last Week" }, { id: 3, title: "Last Month" }, { id: 4, title: 'Custom Range' }];
    activePeriod: number = 1;
    timeout: any;
 
    constructor(
        private reportService: ReportService,
        private utilsService: Utils
    ) {
    }

    startInput: Date;
    endInput: Date;

    setDateRange(event) {
        let dates: Date[] = event;
        this.startInput = dates[0];
        this.endInput = dates[1];
        this.updateFilters();
    }

    updateFilters() {
        switch (this.activePeriod) {
            case 1:
                this.startInput = moment(new Date()).subtract(1, 'd').toDate();
                this.endInput = new Date();
                break;
            case 2:
                this.startInput = moment(new Date()).add(-7, "days").startOf('day').toDate();
                this.endInput = new Date();
                break;
            case 3:
                this.startInput = moment(new Date()).add(-30, "days").startOf('day').toDate();
                this.endInput = moment(new Date()).add(-1, "days").endOf("day").toDate();
                break;
            //   case 4:
            //     this.startInput = moment(new Date()).subtract(1,'d').toDate();
            //     this.endInput = new Date();
            //     break;
        }
        this.loadByDate();
    }

    loadTotals(){
        let _this = this;
        this.reportService.getTokensStats({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format(), limit: 10 }).subscribe(res => {
            _this.statModel = res['data'];
        },
        err => {
            console.log(err);
        })
    }

    isLoading: boolean = false;

    ngOnInit() {
        let _this = this;
        this.updateFilters();
        this.loadTotals();
        for (let v of this.periods) {
            this.periodsMap.set(v.id, v.title);
        }
        // for (let i = 0; i < 10; i++) {
        //     _this.colorList.push(_this.getRandomColor());
        // }
    }

    loadByDate() {
        let _this = this;
        if (isDate(this.startInput) && isDate(this.endInput)) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.loadTotals();
            }, 350);
        }
    }

    getPeriodName(): string {
        return this.utilsService.getFormattedPeriod(this.startInput,this.endInput);
        // let dateStart = moment(this.startInput).format("MMM YYYY");
        // let dateEnd = moment(this.endInput).format("MMM YYYY");
        // return (dateStart + " - " + dateEnd)
    }


    isToday(date) {
        if (moment(date).isSame(new Date(), 'date')) {
            return true;
        } else {
            return false;
        }
    }

}
