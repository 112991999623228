import { ClientModel } from "./client.model";
import { UserModel } from "./user.model";

export class TokenModel {
  id: number;
  guest: ClientModel;
  uuid: string;
  remaining_balance: string;
  expiry_date:string;
  activated_at: boolean;
  transferred_to: ClientModel;
  type: number;
  deleted_at: string;
  created_at: string;
  created_by: UserModel;
  is_blocked:boolean;

  constructor(input?) {
    input = input || {};
    this.id = input.id || '';
    this.guest = input.guest || '';
    this.uuid = input.uuid || '';
    this.remaining_balance = input.remaining_balance || '';
    this.activated_at = input.activated_at || '';
    this.transferred_to = input.transferred_to || '';
    this.type = input.type || '';
    this.is_blocked = input.is_blocked || '';
    this.expiry_date = input.expiry_date || '';

    this.deleted_at = input.deleted_at || '';
    this.created_at = input.created_at || '';
    this.created_by = input.created_by || '';
  }
}