import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { POSModel } from '../../../shared/models/pos.model';
import { POSService } from '../../../shared/services/pos.service';
import { Page } from '../../../shared/models/page.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { Utils } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-pos-list',
  templateUrl: './pos-list.component.html',
  styleUrls: ['./pos-list.component.scss']
})
export class POSListComponent implements OnInit {


  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  freeSearch: string;
  pos: POSModel[];
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];

  searchParams = { per_page: environment.per_page, page: this.currentPage };

  constructor(
    private router: Router,
    private posService: POSService,
    private authService: AuthService,
    private messageService: MessageService,
    private utils: Utils,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.loadFilters();
    this.loadPOS();
    this.columns = [
      { name: 'Name', flexGrow: 1, sortable: true, cellClass: 'POS_name', headerClass: 'POS_name' },
      { name: 'Email', flexGrow: 3, sortable: true, cellClass: 'POS_email', headerClass: 'POS_email' }
    ];
    this.updateMenuItems();
  }

  onSort(event) {
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    this.loadPOS();
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  isLoading:boolean = false;
  loadPOS() {
    let _this = this;
    _this.pos = [];
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    _this.isLoading = true;
    this.posService.getAll(this.searchParams).subscribe(res => {
      res['meta']['per_page'] = +res['meta']['per_page'];
      _this.page = res['meta'];
      _this.pos = res['data'];
      _this.rows = [];
      for (let POS of _this.pos) {
        _this.rows.push(
          {
            id: POS.id,
            description: POS.transaction_ledger_detail,
            last_used_by: POS.last_login ? POS.last_login.pos_user : 'N/A',
            last_used_at: POS.last_login ? POS.last_login.login_at : 'N/A',
            title: POS.title
          }
        );
      }
      if (res['data'].length == 0) {
        //this.messageService.error("No POS Devices Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }
      console.log(_this.page);
      _this.isLoading = false;
      _this.loading.isLoading = false;
    },
      err => {
        _this.isLoading = false;
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    this.searchParams.page = this.currentPage;
    this.loadPOS();
    this.setFilter("pagePOS", "" + this.currentPage);
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  onFocus(event) {
    if (event['type'] == 'click') {
      let id = event['row']['id'];
      this.router.navigate(['/pos/' + id]);
    }
  }

  clearFilters() {
    this.freeSearch = "";
    this.updateFilters();
  }

  historyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  filteredParams: any = {};
  filterUpdateTimeout: any;
  setFilter(name, value) {
    let _this = this;
    _this.filteredParams = { ..._this.filteredParams, [name]: value };
    this.utils.pushFiltersToRouter(value, name, _this.filteredParams).subscribe(res => {
      window.history.pushState(null, "", ("pos" + res));
      _this.historyChange.emit(true);
    });
  }

  getFilter(name): any {
    let value = this.route.snapshot.queryParams[name];
    if (isNaN(+value)) {
      return value;
    }
    return +value;
  }

  saveFilters() {
    let _this = this;
    setTimeout(() => {
      _this.setFilter("pagePOS", _this.currentPage);
      _this.setFilter("searchPOS", _this.freeSearch);
    }, 250);
  }

  loadFilters() {
    this.freeSearch = this.getFilter("searchPOS");
    this.currentPage = (this.getFilter("pagePOS") ? this.getFilter("pagePOS") : 1);
    this.updateFilters();
  }

  filterLoadTimeout: any
  updateFilters(skip?) {
    let _this = this;
    _this.searchParams = { per_page: environment.per_page, page: 1 };
    if (!skip) {
      _this.searchParams.page = _this.currentPage;
    }
    else {
      _this.currentPage = 1;
      _this.setFilter("pagePOS", 1);
    }
    if (this.freeSearch) {
      this.searchParams['q'] = this.freeSearch;
    }
    if (this.filterLoadTimeout) {
      clearTimeout(this.filterLoadTimeout);
    }
    this.filterLoadTimeout = setTimeout(() => {
      this.loadPOS();
    }, 350);
    this.saveFilters();
  }


  //List Dot Menu Bulk Selection

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  openBulk() {
    this.mdSlideUp.show()
  }

  mainToggle: boolean = false;
  activeActionTitle: string;
  menuItems: any[] = [
    { action: 'delete', message: 'Delete Selected', class: '', icon: 'fal fa-trash', permissions: ['delete_pos'] },
  ];

  handleButtonPress(event) {
    switch (event) {
      case "export-selected":
        this.activeActionTitle = "Export Selected";
        this.openBulk();
        break;
      case "delete":
        this.activeActionTitle = "Delete Selected";
        this.openBulk();
        break;
      case "export-all":
        this.activeActionTitle = "Export All";
        this.openBulk();
        break;
      default:
        console.log(event);
        break;
    }
  }


  activeRows: Map<number, any> = new Map<number, any>();
  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if (row['bulk_checked']) {
      _this.activeRows.set(row['id'], row);
    }
    else {
      _this.activeRows.delete(row['id']);
    }
    this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }

  updateMenuItems() {
    for (let item of this.menuItems) {
      if (this.activeRows.size == 0) {
        if (item.action.includes('export-selected')) {
          item.Disabled = true;
        }
      }
      else {
        if (item.action.includes('export-selected')) {
          item.Disabled = false;
        }
      }
    }
    return this.menuItems;
  }

  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if (this.mainToggle == true) {
        _this.activeRows.set(item.id, item);
      }
      else {
        _this.activeRows.delete(item.id);
      }
    }
    this.updateMenuItems();
  }

  submitToggle() {
    let _this = this;
    this.utils.onDelete.subscribe(res => {
      _this.loadPOS();
    });
    this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), 'POS',this.posService);
    this.activeRows = new Map<number, any>();
  }

}