export const Constants = {
    success:'#d2f0ff',
    error:'#ffdae7',
    warning:'#fffce8',
    white:'#ffffff',
    successText:'#00378b',
    errorText:'#9b0b16',
    warningText:'#856404',
}
export const Types = {
    indicator:'indicator',
    intervention:'intervention',
    warning:'warning'
}

export const Responses = {
    accept:'accept',
    decline:'decline',
    ignore:'ignore'
}